// DUSTIN ====================

export const dustbinSVG = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 19 23.3" xml:space="preserve">
<path  d="M11.3,0c0.1,0,0.3,0.1,0.4,0.1c0.8,0.3,1.4,1.1,1.4,2c0,0.2,0,0.5,0,0.8h0.3c1.2,0,2.4,0,3.7,0
   c1-0.1,1.9,0.7,1.9,1.7c0,0.1,0,0.1,0,0.2c0,0.4,0,0.9,0,1.3c0,0.4-0.3,0.7-0.7,0.8c0,0-0.1,0-0.1,0c-5.8,0-11.6,0-17.4,0
   C0.4,7,0,6.7,0,6.3c0,0,0-0.1,0-0.1C0,5.6,0,5,0,4.4c0.1-0.9,0.9-1.5,1.8-1.5c1.2,0,2.5,0,3.7,0h0.3c0-0.2,0-0.4,0-0.6
   c0-1.1,0.7-2,1.8-2.2c0,0,0.1,0,0.1,0H11.3z M11.7,2.9c0-0.2,0-0.5,0-0.7c0-0.4-0.3-0.7-0.7-0.7c-1,0-2,0-2.9,0
   C7.7,1.4,7.4,1.7,7.3,2c0,0.3,0,0.6,0,0.9L11.7,2.9z"/>
<path  d="M9.5,8.4h7.9c0.4,0,0.4,0.1,0.4,0.4c-0.1,1.7-0.2,3.5-0.2,5.2c-0.1,2.4-0.2,4.9-0.4,7.3c-0.1,1.1-1,2-2.1,2
   c-0.9,0-1.8,0-2.6,0c-2.8,0-5.6,0-8.4,0c-1.1,0-2-0.7-2.2-1.8c-0.1-0.5-0.1-1-0.1-1.4C1.6,18,1.5,16,1.4,13.9
   c-0.1-1.7-0.2-3.4-0.2-5.1c0-0.3,0-0.4,0.4-0.4H9.5 M5.1,14.9v0.5c0,1.4,0,2.8,0,4.3c0,0.4,0.3,0.7,0.7,0.7c0.3,0,0.7-0.2,0.7-0.6
   c0-0.1,0-0.2,0-0.3c0-2.8,0-5.6,0-8.4c0-0.3,0-0.6,0-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0
   C5.1,11.8,5.1,13.4,5.1,14.9 M8.8,14.9v0.4c0,1.4,0,2.9,0,4.3c0,0.4,0.3,0.7,0.7,0.7c0.3,0,0.7-0.2,0.7-0.6c0-0.1,0-0.2,0-0.3
   c0-2.8,0-5.6,0-8.4c0-0.3,0-0.6,0-1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7l0,0C8.8,11.8,8.8,13.4,8.8,14.9 M13.9,14.9v-0.2
   c0-1.5,0-2.9,0-4.4c0-0.3-0.2-0.6-0.5-0.7c-0.4-0.1-0.8,0-0.9,0.4c0,0.1-0.1,0.2,0,0.3c0,2.5,0,4.9,0,7.4c0,0.7,0,1.3,0,2
   c0,0.4,0.3,0.7,0.7,0.8c0.4,0,0.7-0.3,0.8-0.7c0,0,0-0.1,0-0.1C13.9,18,13.9,16.5,13.9,14.9"/>
    </svg>`;

// Key / Encryption ====================

export const keyEncrypt = `<svg xmlns="http://www.w3.org/2000/svg" width="566.5" height="566.5" viewBox="0 0 566.5 566.5"><path d="M514.29,52.21C480.62,18.54,435.86,0,388.25,0s-92.37,18.54-126.04,52.21c-48.93,48.93-64.97,121.07-41.94,185.79L3.44,458.59c-2.21,2.24-3.44,5.27-3.44,8.41v87.5c0,6.63,5.37,12,12,12H108.25c3.18,0,6.23-1.26,8.49-3.51l26.25-26.25c2.25-2.25,3.51-5.3,3.51-8.49v-44.88h40.5c6.63,0,12-5.37,12-12v-23h23c6.63,0,12-5.37,12-12v-40.5h40.5c3.31,0,6.47-1.37,8.74-3.78l43.7-46.43c30.1,11.02,62.52,13.72,94.27,7.78,35.37-6.62,67.56-23.62,93.09-49.16,33.67-33.67,52.21-78.43,52.21-126.04s-18.54-92.37-52.21-126.04Zm-16.97,235.11c-22.1,22.1-49.94,36.81-80.53,42.54-29.89,5.6-60.45,2.35-88.37-9.39-4.62-1.94-9.96-.81-13.39,2.84l-45.71,48.57h-47.31c-6.63,0-12,5.37-12,12v40.5h-23c-6.63,0-12,5.37-12,12v23h-40.5c-6.63,0-12,5.37-12,12v51.9l-19.22,19.22H24v-10.77l205.85-209.59c4.26-4.33,4.19-11.3-.14-15.56-4.33-4.26-11.3-4.19-15.56,.14L24,500.33v-28.42L242.78,249.34c3.36-3.41,4.36-8.5,2.56-12.93-23.42-57.61-10.14-123.25,33.84-167.23,29.13-29.13,67.87-45.18,109.07-45.18s79.94,16.04,109.07,45.18c29.13,29.13,45.18,67.87,45.18,109.07s-16.04,79.94-45.18,109.07Z"/><path d="M427.93,91.56c-12.55,0-24.36,4.89-33.23,13.77-8.88,8.88-13.77,20.68-13.77,33.23s4.89,24.36,13.77,33.23c8.88,8.88,20.68,13.77,33.23,13.77s24.36-4.89,33.23-13.77c18.32-18.32,18.32-48.14,0-66.47-8.88-8.88-20.68-13.77-33.23-13.77Zm16.26,63.26c-4.34,4.34-10.12,6.74-16.26,6.74s-11.92-2.39-16.26-6.74c-8.97-8.97-8.97-23.56,0-32.52,4.34-4.34,10.12-6.74,16.26-6.74s11.92,2.39,16.26,6.74c8.97,8.97,8.97,23.56,0,32.52Z"/></svg>`;

// Password Eye =================-

export const passwordEye = `<svg id="eye" class="viewPass" onclick="viewPass('l_password', this.id)" xmlns="http://www.w3.org/2000/svg" width="25.7" height="15.32" viewBox="0 0 25.7 15.32">
        <path
            d="M25.5,7.06C22.22,2.69,17.77,0,12.86,0S3.49,2.69.2,7.05a1.05,1.05,0,0,0,0,1.22c3.29,4.37,7.74,7.05,12.66,7.05s9.36-2.68,12.64-7A1,1,0,0,0,25.5,7.06ZM18.25,8a5.4,5.4,0,1,1,0-.7A4.09,4.09,0,0,1,18.25,8Z"
            transform="translate(0 0)"></path>
        <path
            d="M12.67,4.76A2.91,2.91,0,1,0,13,10.57h0a2.92,2.92,0,0,0,2.72-2.72,2.28,2.28,0,0,0,0-.37A2.9,2.9,0,0,0,12.67,4.76Z"
            transform="translate(0 0)"></path>
    </svg>`;

// Feedback banner X (close) =================-

export const FBX = `<svg onclick="hide('feedbackBanner')" class="xFeedback" xmlns="http://www.w3.org/2000/svg" width="13.58"
                height="13.58" viewBox="0 0 13.58 13.58">
                <polygon class="a"
                    points="12.53 13.53 13.58 12.47 7.84 6.78 13.53 1.05 12.47 0 6.79 5.74 1.05 0.06 0 1.1 5.74 6.79 0.05 12.53 1.1 13.58 6.8 7.84 12.53 13.53" />
            </svg>`;

// warningSVG =================-

export const warningSVG = ` <svg id="warningSVG" xmlns="http://www.w3.org/2000/svg" width="27.61" height="23.73"
                viewBox="0 0 27.61 23.73">
                <defs>
                    <style>
                        .red1 {
                            fill: #db0000;
                        }

                        .white1 {
                            fill: #fff;
                        }
                    </style>
                </defs>
                <path class="red1"
                    d="M27.21,19.64,16,1.22a2.49,2.49,0,0,0-3.4-.88,2.56,2.56,0,0,0-.88.88L.4,19.64a2.78,2.78,0,0,0-.09,2.71,2.54,2.54,0,0,0,2.23,1.38H25.07a2.52,2.52,0,0,0,2.23-1.38A2.76,2.76,0,0,0,27.21,19.64Z"
                    transform="translate(0 0)" />
                <path class="white1"
                    d="M13.92,15.49a1.4,1.4,0,0,0,1.4-1.4V7.8a1.4,1.4,0,0,0-2.8,0v6.29A1.4,1.4,0,0,0,13.92,15.49Z"
                    transform="translate(0 0)" />
                <path class="white1" d="M15.64,19a1.84,1.84,0,1,0-1.75,1.91h0A1.79,1.79,0,0,0,15.64,19Z"
                    transform="translate(0 0)" />
            </svg>`;

// succSVG =================-

export const succSVG = `<svg id="succSVG" class="hide" xmlns="http://www.w3.org/2000/svg" width="25.15" height="25.15"
                viewBox="0 0 25.15 25.15">
                <circle cx="12.58" cy="12.58" r="12.58" />
                <polygon class="ctick"
                    points="19.04 6.7 10.43 15.47 6.08 11.32 4.6 12.85 10.45 18.46 20.55 8.18 19.04 6.7" />
            </svg>`;

// warningSVG+succSVG =================-

export const warningSuccSVGs = `<svg id="warningSVG" xmlns="http://www.w3.org/2000/svg" width="27.61" height="23.73"
                viewBox="0 0 27.61 23.73">
                <defs>
                    <style>
                        .red1 {
                            fill: #db0000;
                        }

                        .white1 {
                            fill: #fff;
                        }
                    </style>
                </defs>
                <path class="red1"
                    d="M27.21,19.64,16,1.22a2.49,2.49,0,0,0-3.4-.88,2.56,2.56,0,0,0-.88.88L.4,19.64a2.78,2.78,0,0,0-.09,2.71,2.54,2.54,0,0,0,2.23,1.38H25.07a2.52,2.52,0,0,0,2.23-1.38A2.76,2.76,0,0,0,27.21,19.64Z"
                    transform="translate(0 0)" />
                <path class="white1"
                    d="M13.92,15.49a1.4,1.4,0,0,0,1.4-1.4V7.8a1.4,1.4,0,0,0-2.8,0v6.29A1.4,1.4,0,0,0,13.92,15.49Z"
                    transform="translate(0 0)" />
                <path class="white1" d="M15.64,19a1.84,1.84,0,1,0-1.75,1.91h0A1.79,1.79,0,0,0,15.64,19Z"
                    transform="translate(0 0)" />
            </svg>
            <svg id="succSVG" class="hide" xmlns="http://www.w3.org/2000/svg" width="25.15" height="25.15"
                viewBox="0 0 25.15 25.15">
                <circle cx="12.58" cy="12.58" r="12.58" />
                <polygon class="ctick"
                    points="19.04 6.7 10.43 15.47 6.08 11.32 4.6 12.85 10.45 18.46 20.55 8.18 19.04 6.7" />
            </svg>`;

// Duplicate =================-

export const duplicateSVG = `<svg id="duplicateSVG" xmlns="http://www.w3.org/2000/svg" width="532.56" height="532.56" viewBox="0 0 532.56 532.56"><path d="M471.52,0H178.08c-33.6,0-61.04,27.44-61.04,61.04v56H61.04C27.44,117.04,0,144.48,0,178.08V471.52c0,33.6,27.44,61.04,61.04,61.04H354.48c33.6,0,61.04-27.44,61.04-61.04v-56h56c33.6,0,61.04-27.44,61.04-61.04V61.04c0-33.6-27.44-61.04-61.04-61.04Zm-90.16,470.96h-.56c0,14.56-11.76,26.32-26.32,26.32H61.6c-14.56,0-26.32-11.76-26.32-26.32V177.52c0-14.56,11.76-26.32,26.32-26.32H355.04c14.56,0,26.32,11.76,26.32,26.32V470.96Zm116.48-116.48c0,14.56-11.76,26.32-26.32,26.32h-56V177.52c0-33.6-27.44-61.04-61.04-61.04H151.76V60.48c0-14.56,11.76-26.32,26.32-26.32H471.52c14.56,0,26.32,11.76,26.32,26.32V354.48Z"/><path d="M299.88,306.88h0s-74.48,0-74.48,0v-74.48c0-9.52-7.84-17.36-17.36-17.36s-17.36,7.84-17.36,17.36v74.48H115.64c-9.52,0-17.36,7.84-17.36,17.36s7.84,17.36,17.36,17.36h74.48v74.48c0,9.52,7.84,17.36,17.36,17.36s17.36-7.84,17.36-17.36v-74.48h75.04c9.52,0,17.36-7.84,17.36-17.36s-7.84-17.36-17.36-17.36Z"/></svg>`;

// Additional Services =================-

export const addSer = `<svg xmlns="http://www.w3.org/2000/svg" width="24.74" height="29.37" viewBox="0 0 24.74 29.37">
        <path
          d="M6.22,10.06a.48.48,0,0,0,.48-.48V5.77A2.59,2.59,0,0,1,9.28,3.19h6.18A2.58,2.58,0,0,1,18,5.77V9.58a.49.49,0,0,0,.48.48h1a5.36,5.36,0,0,1,1.55.2V5.71A5.69,5.69,0,0,0,15.39,0H9.1A5.6,5.6,0,0,0,3.51,5.58h0v4.67a6.28,6.28,0,0,1,1.55-.2Z"
          transform="translate(0 0)" />
        <path
          d="M23.23,12.36a5.1,5.1,0,0,0-3.64-1.5H5.16A5.15,5.15,0,0,0,0,16H0v8.2a5.16,5.16,0,0,0,5.16,5.16H19.58a5.16,5.16,0,0,0,5.16-5.16h0V16A5.15,5.15,0,0,0,23.23,12.36Zm-8.8,8.32a2.08,2.08,0,0,1-.9,1.48h0a.28.28,0,0,0-.13.25v1.86a.31.31,0,0,1-.31.31H11.64a.31.31,0,0,1-.31-.31V22.41a.29.29,0,0,0-.12-.25,2.06,2.06,0,0,1-.91-2,2.08,2.08,0,0,1,4.13.48Z"
          transform="translate(0 0)" /></svg>`;

// D icon for traffic light duplicate services =================-
export const dSVG = `<svg style="height: 22px;" viewBox="0 0 15 15" >
<path d="M13.6,4.6c-0.4-0.9-0.9-1.7-1.6-2.3c-0.7-0.7-1.5-1.2-2.5-1.5c-1-0.4-2-0.6-3.2-0.6H0.8v14.7h5.5c1.1,0,2.2-0.2,3.2-0.6
	c1-0.4,1.8-0.9,2.5-1.6c0.7-0.7,1.2-1.4,1.6-2.3c0.4-0.9,0.6-1.9,0.6-2.9C14.2,6.4,14,5.5,13.6,4.6z M11.1,9.5
	c-0.2,0.6-0.6,1.1-1,1.6c-0.4,0.4-1,0.8-1.6,1c-0.6,0.2-1.3,0.4-2.1,0.4H3.4v-10h2.9c0.8,0,1.5,0.1,2.1,0.4C9.1,3.1,9.6,3.5,10,3.9
	c0.4,0.5,0.8,1,1,1.6c0.2,0.6,0.4,1.3,0.4,2C11.4,8.3,11.3,8.9,11.1,9.5z"/>
</svg>`;

// left arrow =================-
export const leftArrowSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="8.56" height="14.97" viewBox="0 0 8.56 14.97"><path d="m.3,8.24l6.42,6.42c.19.2.45.31.72.31.28,0,.54-.1.75-.29.2-.19.31-.45.32-.73,0-.28-.1-.55-.3-.76L2.52,7.5,8.14,1.87c.24-.18.4-.46.42-.77.02-.29-.09-.58-.3-.8-.2-.2-.46-.3-.71-.3-.03,0-.06,0-.09,0-.29.02-.57.16-.71.35L.3,6.78c-.4.41-.4,1.05,0,1.46Z"/></svg>`;

// right arrow =================-
export const rightArrowSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="8.56" height="14.97" viewBox="0 0 8.56 14.97"><path d="m8.25,6.73L1.83.31C1.64.12,1.39,0,1.11,0c-.28,0-.54.1-.75.29C.17.48.05.74.05,1.02c0,.28.1.55.3.76l5.7,5.69L.42,13.1c-.24.18-.4.46-.42.77-.02.29.09.58.3.8.2.2.46.3.71.3.03,0,.06,0,.09,0,.29-.02.57-.16.71-.35l6.45-6.43c.4-.41.4-1.05,0-1.46Z"/></svg>`;

// Manager Feed back =================-
export const mfSVG = `<svg width="15.02" height="7.95" viewBox="0 0 15.02 7.95"><polygon points="4.01 4.52 1.66 0 0 0 0 7.95 1.54 7.95 1.54 2.69 3.59 6.6 4.43 6.6 6.48 2.69 6.48 7.95 8.02 7.95 8.02 0 6.38 0 4.01 4.52"/><polygon points="15.02 1.35 15.02 0 9.68 0 9.68 7.95 11.22 7.95 11.22 4.66 14.38 4.66 14.38 3.4 11.22 3.4 11.22 1.35 15.02 1.35"/></svg>`;

// candidate Feedback =================-
export const cfSVG = `<svg  width="15.05" height="8.98" viewBox="0 0 15.05 8.98"><path d="m6.13,6.73c-.17.17-.35.31-.54.42-.2.11-.4.19-.62.24s-.42.07-.62.07c-.38,0-.73-.08-1.05-.25s-.59-.39-.82-.66-.41-.59-.53-.95c-.12-.36-.19-.74-.19-1.13,0-.35.05-.7.16-1.05.11-.35.27-.67.48-.95s.48-.51.8-.68c.32-.17.7-.26,1.13-.26.2,0,.4.02.61.07s.41.12.6.22c.19.1.37.23.54.41s.31.38.42.63l1.32-.91c-.29-.57-.73-1.04-1.33-1.4-.59-.36-1.3-.54-2.12-.54-.69,0-1.31.13-1.85.39s-1,.59-1.37,1.01c-.37.42-.66.89-.85,1.42-.2.53-.29,1.06-.29,1.6s.11,1.12.32,1.67.51,1.03.89,1.46.83.78,1.35,1.04c.52.27,1.1.4,1.73.4.36,0,.72-.05,1.1-.14.37-.09.73-.23,1.05-.4s.62-.39.89-.64c.26-.25.46-.55.61-.88l-1.41-.81c-.1.24-.23.45-.4.62Z"/><polygon points="15.05 1.56 15.05 .05 9.1 .05 9.1 8.91 10.82 8.91 10.82 5.24 14.34 5.24 14.34 3.84 10.82 3.84 10.82 1.56 15.05 1.56"/></svg>`;

// candidate dev report =================-
export const cdrSVG = `<svg  width="23.46" height="8.37" viewBox="0 0 23.46 8.37"><path d="m5.7,6.27c-.15.16-.32.29-.51.39-.18.1-.37.17-.57.22s-.4.07-.58.07c-.36,0-.68-.08-.98-.23s-.55-.36-.77-.62-.38-.55-.49-.89c-.12-.34-.17-.69-.17-1.05,0-.33.05-.65.15-.98.1-.33.25-.62.45-.88s.44-.47.74-.63c.3-.16.65-.24,1.05-.24.19,0,.38.02.57.06s.38.11.56.2c.18.09.34.22.5.38s.29.35.4.59l1.23-.85c-.27-.53-.68-.97-1.24-1.3-.55-.33-1.21-.5-1.97-.5-.64,0-1.22.12-1.72.36-.5.24-.93.55-1.28.94-.35.39-.61.83-.8,1.32-.18.49-.27.99-.27,1.49s.1,1.04.3,1.55c.2.51.47.96.82,1.36s.77.72,1.26.97c.49.25,1.03.37,1.62.37.33,0,.67-.04,1.02-.13.35-.09.68-.21.98-.37.31-.16.58-.36.82-.6s.43-.51.56-.82l-1.31-.76c-.09.22-.22.42-.37.57Z"/><path d="m14.51,1.26c-.34-.37-.77-.67-1.27-.88-.51-.22-1.1-.33-1.77-.33h-3v8.25h3c.62,0,1.18-.1,1.68-.3s.93-.48,1.28-.84.63-.79.82-1.3.28-1.07.28-1.7c0-.57-.09-1.1-.26-1.6-.17-.5-.43-.94-.77-1.31Zm-.76,3.99c-.11.33-.27.62-.47.87-.21.24-.46.44-.77.57-.31.14-.66.21-1.05.21h-1.39V1.45h1.39c.39,0,.73.07,1.04.2.31.13.56.32.77.56.21.24.37.53.48.86.11.33.16.7.16,1.09s-.05.75-.16,1.08Z"/><path d="m21.58,5.19c.46-.19.82-.51,1.09-.95.27-.44.4-.92.4-1.46,0-.33-.07-.66-.2-.99-.13-.33-.31-.62-.55-.88-.23-.26-.51-.47-.83-.63s-.67-.24-1.05-.24h-3.65v8.25h1.6v-2.77h1.58l1.69,2.77h1.8l-1.88-3.1Zm-.21-1.88c-.05.16-.13.3-.22.42-.09.12-.2.22-.33.28s-.27.1-.41.1h-2.01V1.45h1.94c.14,0,.28.03.41.1.14.07.26.16.36.28s.19.26.25.42c.06.16.09.34.09.53s-.03.36-.08.52Z"/></svg>`;

// candidate MCGF =================-
export const mcgfSVG = `<svg width="29.41" height="9.86" viewBox="0 0 29.41 9.86"><defs><style>.cls-1{stroke-width:0px;}</style></defs><polygon class="cls-1" points="4.89 5.59 2.02 .07 0 .07 0 9.78 1.89 9.78 1.89 3.35 4.39 8.14 5.41 8.14 7.92 3.35 7.92 9.78 9.8 9.78 9.8 .07 7.79 .07 4.89 5.59"/><path class="cls-1" d="m17.95,7.39c-.18.19-.38.34-.59.46-.21.12-.44.2-.68.26s-.47.08-.68.08c-.42,0-.8-.09-1.16-.27s-.65-.42-.9-.72-.44-.65-.58-1.05c-.14-.4-.2-.81-.2-1.24,0-.38.06-.77.18-1.16.12-.39.29-.73.53-1.04s.52-.55.87-.74c.35-.19.76-.29,1.24-.29.22,0,.44.02.67.08s.45.13.66.24c.21.11.41.26.59.44s.34.42.47.69l1.45-1c-.32-.63-.8-1.14-1.46-1.53C17.69.21,16.92.01,16.02.01c-.76,0-1.43.14-2.02.42-.59.28-1.09.65-1.5,1.11-.41.46-.72.97-.94,1.55-.21.58-.32,1.16-.32,1.76s.12,1.23.35,1.82c.23.6.56,1.13.97,1.6s.91.85,1.48,1.14c.57.29,1.21.44,1.9.44.39,0,.79-.05,1.2-.15.41-.1.8-.25,1.16-.44.36-.19.68-.43.97-.7s.51-.6.66-.96l-1.55-.89c-.11.26-.26.49-.44.68Z"/><path class="cls-1" d="m25.9,4.79v1.38h1.95v.68c-.76.88-1.6,1.33-2.54,1.33-.41,0-.79-.08-1.14-.25s-.65-.4-.91-.69c-.26-.29-.46-.64-.6-1.03-.15-.4-.22-.82-.22-1.28s.07-.85.2-1.24c.13-.39.32-.74.56-1.04s.53-.54.87-.71c.34-.17.72-.26,1.13-.26.52,0,1,.13,1.44.38.44.26.79.62,1.03,1.11l1.41-1.04c-.33-.65-.82-1.16-1.47-1.55-.65-.38-1.43-.57-2.34-.57-.67,0-1.3.13-1.87.39-.57.26-1.07.61-1.5,1.05s-.76.96-1,1.55c-.24.59-.36,1.21-.36,1.88,0,.7.12,1.36.36,1.96s.57,1.13.98,1.58c.41.45.9.8,1.46,1.05.56.26,1.16.38,1.8.38,1.03,0,1.93-.39,2.71-1.16v1.09h1.56v-4.99h-3.51Z"/></svg>`;

export const iSVG = `<svg class="iSVG" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M6,0C2.69,0,0,2.69,0,6s2.69,6,6,6,6-2.69,6-6S9.31,0,6,0Zm0,11c-2.76,0-5-2.24-5-5S3.24,1,6,1s5,2.24,5,5-2.24,5-5,5Z"/><rect x="5.62" y="4.56" width=".77" height="4.65"/><rect x="5.62" y="2.79" width=".77" height=".94"/></svg>`;

export const tickCircleSVG = `
 <svg class="primaryColor" width="22" height="22" viewBox="0 0 15 15"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="m7.5,0C3.36,0,0,3.36,0,7.5s3.36,7.5,7.5,7.5,7.5-3.36,7.5-7.5S11.64,0,7.5,0Zm-1.27,11.01l-3.49-3.35.88-.91,2.59,2.47,5.14-5.24.9.89-6.02,6.13Z"/></svg>`;

export const crossCircleSVG = `
 <svg class="errorColor" width="22" height="22" viewBox="0 0 15 15"><defs><style>.cls-1{stroke-width:0px;}</style></defs><path class="cls-1" d="m7.5,0C3.36,0,0,3.36,0,7.5s3.36,7.5,7.5,7.5,7.5-3.36,7.5-7.5S11.64,0,7.5,0Zm3.02,11.41l-3.01-2.99-2.99,3.02-.92-.92,2.98-3.01-3.01-2.99.92-.92,3.01,2.98,2.98-3.01.93.92-2.98,3.01,3.01,2.98-.92.93Z"/></svg>
`;

export const linkOpenTabSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
  <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd" />
  <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd" />
</svg>
`;

export const additionalServicesIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.4 23.4">
<g>
	<path d="M8.9,19L8.9,19c-2,0-4,0-6,0c-0.9,0-1.4-0.5-1.4-1.4c0-4.9,0-9.9,0-14.8C1.5,2,2,1.5,2.9,1.5c3.6,0,7.3,0,10.9,0
		c0.9,0,1.4,0.5,1.4,1.4c0,1.9,0,3.8,0,5.7c0,0.5,0.3,0.8,0.7,0.8c0.4,0,0.7-0.3,0.7-0.8c0-2,0-3.9,0-5.9c0-1.5-1.2-2.7-2.7-2.7
		C10.2,0,6.4,0,2.7,0C1.8,0,1.1,0.4,0.5,1.1C0.2,1.4,0.1,1.9,0,2.3v15.8c0,0,0,0.1,0,0.1c0.2,1.2,1.1,2,2.3,2.2c0.5,0.1,0.9,0,1.4,0
		c1.7,0,3.5,0,5.2,0c0.3,0,0.6-0.1,0.8-0.5C9.8,19.5,9.5,19,8.9,19z"/>
	<path d="M23.3,16.1L23.3,16.1c-0.5-3.4-3.9-5.8-7.2-5.2c-3.4,0.6-5.7,3.6-5.3,7.1c0.3,2.5,2.1,4.6,4.5,5.3c0.4,0.1,0.9,0.2,1.3,0.3
		h1c0.2,0,0.4-0.1,0.7-0.1C21.7,22.7,23.9,19.5,23.3,16.1z M17.1,22c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9
		c2.7,0,4.9,2.2,4.9,4.9C22,19.8,19.8,22,17.1,22z"/>
	<path d="M3.8,9.3c3,0,6,0,9,0c0.1,0,0.2,0,0.3,0c0.3-0.1,0.4-0.2,0.5-0.5c0.1-0.5-0.2-0.9-0.8-0.9c-1.5,0-3.1,0-4.6,0
		c-1.4,0-2.7,0-4.1,0c-0.2,0-0.4,0-0.6,0C3.3,7.8,3.1,8,3,8.3C2.9,8.6,3,8.9,3.2,9.1v0C3.4,9.2,3.6,9.3,3.8,9.3z"/>
	<path d="M8.9,11.7c-0.9,0-1.7,0-2.6,0c-0.9,0-1.7,0-2.6,0c-0.5,0-0.8,0.3-0.8,0.7c0,0.4,0.3,0.7,0.8,0.7c1.7,0,3.5,0,5.2,0
		c0.5,0,0.8-0.3,0.8-0.7S9.4,11.7,8.9,11.7z"/>
	<path d="M3.7,5.4L3.7,5.4c0.7,0,1.4,0,2.1,0c0.7,0,1.4,0,2.2,0c0.5,0,0.8-0.3,0.8-0.7c0-0.4-0.3-0.7-0.8-0.7c-1.4,0-2.8,0-4.3,0
		c-0.5,0-0.8,0.3-0.8,0.8C2.9,5.1,3.3,5.4,3.7,5.4z"/>
	<path d="M20.1,16.5c-0.1-0.1-0.3-0.1-0.5-0.1c-0.6,0-1.2,0-1.8,0c0-0.7,0-1.3,0-1.9c0-0.5-0.3-0.8-0.7-0.8c-0.4,0-0.7,0.3-0.7,0.8
		c0,0.5,0,1,0,1.4v0.5c-0.1,0-0.2,0-0.3,0c-0.5,0-1.1,0-1.7,0c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.7,0.8,0.7c0.3,0,0.6,0,0.9,0
		c0.3,0,0.7,0,1,0v0.3c0,0.5,0,1.1,0,1.6c0,0.5,0.3,0.8,0.7,0.8c0.4,0,0.7-0.3,0.7-0.8c0-0.5,0-1.1,0-1.6v-0.3c0.1,0,0.2,0,0.3,0
		c0.6,0,1.1,0,1.7,0c0.3,0,0.6-0.2,0.7-0.6C20.6,16.9,20.4,16.6,20.1,16.5z"/>
</g>
</svg>`;

export const timerIcon = `<svg 
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 23.9 23.9"
	>
<g>
	<path d="M8,14L8,14c0-0.6-0.4-1-1-1H1c-0.5,0-1,0.4-1,1V14c0,0.5,0.4,0.9,1,0.9H7C7.5,14.9,8,14.5,8,14z"/>
	<path d="M2.9,10.9H9c0.5,0,0.9-0.4,0.9-0.9V9.9C10,9.4,9.5,9,9,9H2.9C2.4,9,2,9.4,2,9.9V10C2,10.5,2.4,10.9,2.9,10.9z"/>
	<path d="M12.6,2.6L12.6,2.6c0.9-0.1,1.7-0.1,2.6,0c0.4,0,0.7-0.3,0.7-0.6V0.6c0-0.3-0.3-0.6-0.6-0.6h-2.7c-0.3,0-0.6,0.3-0.6,0.6V2
		C11.9,2.3,12.2,2.6,12.6,2.6z"/>
	<path d="M13.9,4c-2.8,0-5.3,1.1-7.1,3h3.3c1.1-0.6,2.4-1,3.8-1c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8c-1.4,0-2.7-0.4-3.8-1H6.9
		c1.8,1.8,4.3,3,7.1,3c5.5,0,10-4.5,10-10C23.9,8.4,19.4,4,13.9,4z"/>
	<path d="M14.3,12c-0.7-0.1-1.4,0.1-1.9,0.6c-0.7,0.8-0.7,2.1,0.2,2.8s2.1,0.7,2.8-0.2c0.5-0.6,0.6-1.3,0.4-1.9l2.6-4L18,8.9v0
		L14.3,12z"/>
	<path d="M10,18v-0.2c0-0.5-0.4-0.9-0.9-0.9H3.9c-0.5,0-0.9,0.4-0.9,0.9V18c0,0.5,0.4,0.9,0.9,0.9H9C9.6,18.9,10,18.5,10,18z"/>
</g>
</svg>`;

export const scientistIcon = `<svg
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 23.1 24.9">
<g>
	<path d="M21.1,12.9c-0.1-0.1-0.2-0.3-0.3-0.5l0.2-0.2c0.8-0.9,1.3-1.7,1.7-2.6c0.2-0.6,0.5-1.4,0.4-2.2c-0.1-1.3-0.9-2.1-2.2-2.6
		c-1.2-0.4-2.4-0.3-3.4-0.2c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0l-0.1-0.2C16.2,4.4,16.1,4.2,16,4c0-0.1,0-0.2,0-0.3
		c0.5-0.6,0.5-1.4,0.1-2c-0.4-0.6-1.1-1-1.9-0.8c-0.2,0-0.3,0-0.4-0.1c-0.8-0.7-1.7-0.9-2.6-0.7C10.7,0.2,10,0.6,9.5,1.2
		C8.7,2.1,8.1,3.1,7.6,4.6c0,0.1-0.1,0.2-0.1,0.2l-0.1,0c-0.1,0-0.2,0-0.3-0.1c-1-0.2-2.3-0.3-3.7,0c-1,0.2-1.8,0.7-2.2,1.5
		C0.8,6.9,0.7,7.8,1,8.8c0.2,0.8,0.6,1.7,1.3,2.6c0.2,0.3,0.4,0.5,0.6,0.8C3,12.2,3,12.3,3.1,12.4L3,12.6c-0.2,0.2-0.3,0.4-0.5,0.6
		c-0.1,0.1-0.2,0.3-0.3,0.4C2.1,13.7,2,13.9,1.9,14c0,0,0,0-0.1,0c-0.8,0-1.5,0.5-1.7,1.2c-0.2,0.7,0,1.5,0.6,2
		c0.1,0,0.1,0.1,0.1,0.2c0.1,1.2,0.8,2.1,2,2.6c1.2,0.5,2.5,0.4,3.7,0.3c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.2,0,0.2,0
		c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.4,0.4,0.9,0.6,1.3c0.5,1.1,1.2,1.9,1.9,2.4c0.5,0.4,1.1,0.6,1.7,0.6c0.6,0,1.2-0.2,1.7-0.6
		c0.3-0.2,0.6-0.5,0.9-0.8c0.7-0.8,1.3-1.9,1.7-3.2c0-0.1,0.1-0.1,0.2-0.1c1.1,0.2,2.1,0.3,3.1,0.2c0.1,0,0.2,0,0.2,0.1
		c0.6,0.6,1.4,0.7,2.1,0.3c0.7-0.4,1.1-1.2,0.9-2c0-0.1,0-0.2,0-0.2c0.2-0.5,0.3-1.1,0.2-1.6l0,0C23,15.9,22.6,15,21.9,14
		C21.7,13.6,21.4,13.2,21.1,12.9z M3.6,18.8c-0.3-0.1-0.6-0.2-1-0.5c-0.2-0.2-0.4-0.4-0.4-0.8c0.6-0.2,1.1-0.6,1.3-1.2l0,0
		c0.2-0.6,0.1-1.2-0.3-1.7c0.3-0.4,0.6-0.8,0.9-1.2l0,0l0.4,0.3c0.4,0.3,0.7,0.6,1,0.9C5.6,14.8,5.8,15,6,15.1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0.9,0.3,1.8,0.4,2.7l0.1,0.5C5.9,19,4.8,19.2,3.6,18.8z M5,12.5l1.5-1.3v2.6
		L5,12.5z M7,6.6L7,6.6C7,7,6.9,7.5,6.8,7.9c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.4-0.1,0.5c0,0.1,0,0.1-0.1,0.2c-0.7,0.6-1.3,1.1-2,1.7
		L4,11.5C3.2,10.4,2.4,9.4,2.2,8C2,7.2,2.4,6.5,3.1,6.2C3.5,6.1,4,6,4.6,5.9c0.2,0,0.4,0,0.6,0c0.7,0,1.3,0.1,1.9,0.2L7,6.6z
		 M15.4,6.4l0.4,1.9L13.6,7h0h0C13.8,7,14,6.9,14.2,6.8c0.3-0.1,0.6-0.2,0.9-0.3L15.4,6.4z M10.6,2c0.3-0.3,0.6-0.5,1-0.6
		c0.6-0.1,1.1,0.1,1.5,0.5c-0.2,0.4-0.2,0.8-0.1,1.3l0,0c0.2,0.8,0.9,1.3,1.7,1.3c0.1,0,0.1,0,0.2,0.1c0,0.2,0.1,0.3,0.2,0.5l0,0.1
		c0,0,0,0,0,0l-0.4,0.1c-0.8,0.3-1.7,0.6-2.5,0.9c-0.1,0-0.2,0-0.2,0c-0.8-0.3-1.6-0.6-2.4-0.9L8.9,5.1C9.2,4.1,9.7,2.9,10.6,2z
		 M10.3,7L8.1,8.3l0.4-1.9L10.3,7z M8.1,16.6l2.2,1.3c-0.6,0.2-1.2,0.4-1.8,0.6L8.1,16.6z M13.4,22.7c-0.4,0.4-0.8,0.7-1.2,0.7
		c-0.6,0.1-1,0-1.6-0.6c-0.8-0.7-1.2-1.6-1.6-2.5C9,20.2,9,20.1,8.9,19.9l0-0.1c0.1,0,0.3-0.1,0.4-0.1v0c0.4-0.1,0.8-0.3,1.2-0.4
		c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.1,0,0.2,0c0.9,0.3,1.7,0.6,2.6,0.9l0.4,0.2
		C14.7,20.8,14.2,21.9,13.4,22.7z M15.5,18.5l-1.9-0.6l2.2-1.3L15.5,18.5z M16.1,13.1c0,0.5,0,1.1-0.1,1.6c0,0.1-0.1,0.2-0.1,0.2
		c-1.2,0.8-2.5,1.6-3.8,2.2v0c-0.1,0-0.2,0-0.2,0c-1.3-0.6-2.6-1.4-3.9-2.2c-0.1,0-0.1-0.1-0.1-0.2c-0.1-1.5-0.1-3,0-4.5
		c0,0,0.1-0.1,0.1-0.1c1.2-0.9,2.5-1.6,3.9-2.2c0,0,0.1,0,0.1,0s0.1,0,0.1,0c1.3,0.6,2.6,1.4,3.9,2.2c0.1,0.1,0.1,0.1,0.1,0.3
		c0,0.6,0,1.1,0.1,1.7l0,0.5L16.1,13.1z M20.3,6.1c0.1,0,0.2,0,0.3,0.1c0.9,0.3,1.3,1,1.1,2c-0.2,1-0.7,1.8-1.1,2.4
		c-0.2,0.2-0.4,0.5-0.5,0.7c-0.1,0.1-0.1,0.2-0.2,0.2l-0.3-0.3c-0.3-0.3-0.6-0.6-0.9-0.8c-0.2-0.2-0.5-0.4-0.7-0.6
		c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.1-0.1-0.1-0.1c-0.2-0.9-0.3-1.8-0.4-2.6l-0.1-0.5C17.9,5.9,19.1,5.8,20.3,6.1z M18.9,12.5
		l-1.5,1.3v-2.6L18.9,12.5z M20.2,17.7c-0.5,0.3-0.8,0.7-0.8,1.3c-0.9,0.1-1.7,0-2.5-0.2l0.1-0.7l0,0c0.1-0.6,0.2-1.1,0.3-1.7l0-0.1
		c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.5,0.4-0.7c0.5-0.4,1-0.9,1.5-1.3c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0,0,0.1-0.1l0.1,0.2
		c0.9,1.2,1.8,2.3,1.8,3.9C21.1,17.3,20.6,17.4,20.2,17.7z"/>
	<path d="M12,10.1L12,10.1c-1.3,0-2.4,1-2.4,2.3c0,1.3,1,2.4,2.3,2.4c1.3,0,2.4-1,2.4-2.3C14.3,11.2,13.3,10.1,12,10.1z"/>
</g>
</svg>
`;

export const supervisionIcon = `<svg
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32.4 16.8"
	>
<g>
	<path d="M32,7.6c-2.8-3.2-6.2-5.4-10.2-6.7C18.4-0.2,15-0.3,11.6,0.6C8,1.6,4.8,3.4,2,5.9C1.3,6.6,0.7,7.4,0,8.1c0,0.2,0,0.3,0,0.5
		C0.1,8.8,0.2,9,0.4,9.2C2.8,12,5.8,14,9.2,15.4c3.2,1.3,6.4,1.8,9.8,1.2c5.2-0.9,9.5-3.5,13-7.4C32.5,8.6,32.5,8.2,32,7.6z
		 M9.4,13.4c-1.6-0.4-6-3.4-7.1-5c1.1-1.5,5.5-4.5,7.1-5C7.3,6.7,7.3,10,9.4,13.4z M22.7,8.4c0,3.6-2.9,6.5-6.5,6.5
		s-6.5-2.9-6.5-6.5c0-3.6,2.9-6.4,6.5-6.4C19.8,1.9,22.7,4.8,22.7,8.4L22.7,8.4z M23,3.4c1.2,0.1,5.5,3.1,7.2,5
		c-2.1,2.1-4.4,3.8-7.2,5C25.1,10,25.1,6.7,23,3.4z"/>
	<path d="M16.2,5.2c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2C19.4,6.6,18,5.2,16.2,5.2z M16.2,9.7
		c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C17.5,9.1,16.9,9.7,16.2,9.7z"/>
</g>
</svg>
`;

export const dragDotsSVG = `<svg class="dragDots"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2.8 12.9" xml:space="preserve">

<g>
	<path class="st0" d="M1.4,2.8c0.8,0,1.4-0.6,1.4-1.4C2.8,0.6,2.2,0,1.4,0C0.6,0,0,0.6,0,1.4C0,2.2,0.6,2.8,1.4,2.8z"/>
	<path class="st0" d="M1.4,5.1C0.6,5.1,0,5.7,0,6.5c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C2.8,5.7,2.2,5.1,1.4,5.1z"/>
	<path class="st0" d="M1.4,10.1c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C2.8,10.8,2.2,10.1,1.4,10.1z"
		/>
</g>
</svg>`;

export const crossSVG = `
 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
`;

export const additionalAssRedSVG = `<svg id="addAssRed" xmlns="http://www.w3.org/2000/svg" width="14.14" height="15.14" viewBox="0 0 14.14 15.14"><defs><style>.cls-1{fill:#E8212D;stroke-width:0px;}</style></defs><path class="cls-1" d="m8.38,0h-2.6L0,15.14h3.03l1.39-3.77h5.29l1.41,3.77h3.03L8.38,0Zm-3.47,9.3l2.17-5.95,2.09,5.95h-4.26Z"/></svg>`;

export const additionalAssGreenSVG = `<svg id="addAssGreen" xmlns="http://www.w3.org/2000/svg" width="14.14" height="15.14" viewBox="0 0 14.14 15.14"><defs><style>.greenFill{fill:#1d9f86;stroke-width:0px;}</style></defs><path class="greenFill" d="m8.38,0h-2.6L0,15.14h3.03l1.39-3.77h5.29l1.41,3.77h3.03L8.38,0Zm-3.47,9.3l2.17-5.95,2.09,5.95h-4.26Z"/></svg>`;

// Copy =================-
export const repeatSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>
`;

// Copy =================-
export const newnewnewSVG = ``;
