// CORE ===================
import { SWITCHBOX, buildClientList, cObj, clientListener, directTab, getCookie, toggleSelectsection, buildDropDownMain, changeTabN, initPageInstance, changeSubTab, id, hide, unhide, eListen } from './core';

// CANDI ===================
import { login, homePage, UPt_myMessages, UPt_profile, UPt_myCV, MAt_consent, MAt_assessments, takeAssessment, myDevelopment, MAt_reports, UserNominations, UNtguide, eFeedback, USt_selections, UStguide, UserSelections, UNthistory, UNt_nominations } from './candi';

// ADAM =======================
import {
  AdminApplications,
  SanitizeApplication,
  SanitizeAppDiscHeaders,
  SanitizeAppGAPHeaders,
  SanitizeAppAAHeaders,
  SAt_contactForm,
  CFs_email,
  CFs_SMS,
  SAt_addAssessments,
  SAt_history,
  SAt_addServices,
  SAt_manStatus,
  ManageUsers,
  MUtUsers,
  MUt_AdvSearch,
  MUt_CompareUsers,
  assAssessment,
  addUser,
  AUt_addUser,
  AUt_bulkUser,
  EUt_profile,
  EUt_messageBox,
  EUt_navigation,
  EUt_allocateClient,
  EUt_userGroups,
  EUt_userAssessments,
  EUt_reports,
  EUt_results,
  EUt_developmentPlan,
  EUt_projects,
  EUt_LMS,
  EUt_busEntAlloc,
  uploadResults,
  ManageApplications,
  ViewAppDetails,
  AngloApplications,
  RecordAngloApp,
  ViewAngloApplication,
  DiscoveryApplications,
  ViewDiscoveryApplication,
  RecordDiscoveryApplication,
  applications,
  viewApplication,
  report,
  additionalService,
  ManageJobs,
  RecordJob,
  EditJobs,
  MJtJobSpecs,
  MJtApplications,
  MJtCom,
  MJtAss,
  MJtReports,
  optionalFieldsObj,
  AddReport,
  ManageReports,
  EditReport,
  MRtReportHeaders,
  MRtMsgEvents,
  ResourceRegister,
  viewReportRules,
  editReportRules,
  adminReporting,
  assStatusReport,
  bookSLA,
  reportSLA,
  jobLevels,
  servicePBUnit,
  jobPublication,
  jobFamily,
  costCenter,
  assBookSLA,
  assPurpose,
  exportData,
  reportRegister,
  reportReview,
  reportFailure,
  AADBBilling,
  MOt_Reports,
  adminReports,
  MRtUserRoles,
} from './adam';

// IMAGES ====================
import editPencilIcon from '../assets/img/icons/editPencil.png';

// DIST ======================
export const dist = {
  login: (recipe, hdrs, vars) => {
    vars.languageID = getCookie('mbohvbhfje') || createCookie('mbohvbhfje', 1);
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        login.loginPage();
        homePage.buildLangDD();
        eListen(
          'forgotPassLink',
          () => {
            login.forgotPass();
          },
          'click'
        );
        eListen(
          'supportPage',
          () => {
            login.openSupportVid();
          },
          'click'
        );
        eListen(
          'backToLogin',
          () => {
            login.backToLogin();
          },
          'click'
        );
        eListen(
          'DDOverlay',
          () => {
            login.langLoginDD();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  loginContactUs: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        login.loginPage();
        homePage.buildLangDD();

        eListen(
          'fileAttachInp',
          () => {
            login.attachSupFile();
          },
          'change'
        );

        eListen(
          'sendBtn',
          () => {
            login.supportRequest();
          },
          'click'
        );

        eListen(
          'removeIcon',
          () => {
            login.rmvFile();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  loginSupport: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        login.loginPage();
      })
      .catch(console.error());
  },
  homePage: (recipe, hdrs, vars) => {
    recipe.methods = ['getCandiNavigationElements', 'getClientsDD', 'getHTMLLexicon'];
    vars.HTMLPageName = 'homePage';
    //getNavigationElements old nav incasies
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        buildClientList(cObj(res.data.getClientsDD), 'HCLIENTID', 'SHORTNAME', 'clientDD');
        clientListener();
        homePage.buildNav(res.data);
      })
      .catch(console.error());
  },
  landingPageIntegrate: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {})
      .catch(console.error());
  },
  profileMaster: (recipe, hdrs, vars) => {
    // recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        // toggleSelectsection(['page', 'company']);
        // changeTabN('UPt_profile')
      })
      .catch(console.error());
  },
  userProfile: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['']);
        // directTab(vars);
      })
      .catch(console.error());
  },
  UPt_profile: (recipe, hdrs, vars) => {
    vars.detailsUserID = hdrs.HuserID;
    recipe.methods = ['getUserDets', 'getDomElementsPerRole'];
    SWITCHBOX(recipe, hdrs, vars)
      .then(async (res) => {
        await UPt_profile.profilePage(res.data.getUserDets[0], res.data.getDomElementsPerRole);
        eListen(
          'image_file',
          () => {
            UPt_profile.selectDP();
          },
          'change'
        );
        eListen(
          'f_resCountry',
          () => {
            notEmptyVal('f_resCountry');
            UPt_profile.selectCountry();
          },
          'change'
        );
        eListen(
          'changePassBtn',
          () => {
            UPt_profile.changePassBox();
          },
          'click'
        );
        eListen(
          'f_profile',
          () => {
            UPt_profile.updateProfile();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  UPt_myMessages: (recipe, hdrs, vars) => {
    if (id('detailsUserID').value) {
      vars.messageUserID = id('detailsUserID').value;
    } else {
      vars.messageUserID = hdrs.HuserID;
    }
    vars.displaypage = 1;
    vars.displayListCount = 25;
    recipe.methods = ['getCommsForUser'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        UPt_myMessages.buildMsgPage(res.data.getCommsForUser, res.data.getHTMLLexicon);
        eListen(
          'inbox',
          () => {
            UPt_myMessages.inbox('inbox');
          },
          'click'
        );
        eListen(
          'sent',
          () => {
            UPt_myMessages.sent('sent');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  UPt_myCV: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        eListen(
          'removeSelFile',
          () => {
            UPt_myCV.removeSelectedFile();
          },
          'click'
        );
        eListen(
          'uploadCvBtn',
          () => {
            UPt_myCV.uploadCV(); //qwerty
          },
          'click'
        );
        eListen(
          'uploadResultsBtn',
          () => {
            UPt_myCV.uploadLI();
          },
          'click'
        );
        eListen(
          'fileCV',
          () => {
            UPt_myCV.displayFile();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  myassessments: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    recipe.methods = ['getAllocatedAssessments'];
    SWITCHBOX(recipe, hdrs, vars)
      .then(async () => {
        toggleSelectsection(['']);
        // await directTab(vars);
      })
      .catch(console.error());
  },
  MAt_guide: (recipe, hdrs, vars) => {
    recipe.methods = [];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {})
      .catch(console.error());
  },
  MAt_consent: (recipe, hdrs, vars) => {
    recipe.methods = ['getConsentForm'];
    vars.consentType = 'assessment';
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        MAt_consent.consentPage(res.data.getConsentForm, res.data.getHTMLLexicon);
        eListen(
          'OTPsubmit',
          () => {
            MAt_consent.giveConsentOTP();
          },
          'click'
        );
        eListen(
          'tickSubmit',
          () => {
            MAt_consent.giveConsentTick();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  MAt_current: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        MAt_assessments.currPage('currBlock');
        eListen(
          'currBlock',
          () => {
            MAt_assessments.currPage('currBlock');
          },
          'click'
        );
        eListen(
          'compBlock',
          () => {
            MAt_assessments.compPage('compBlock');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  takeAssessment: (recipe, hdrs, vars) => {
    recipe.methods = ['getSurveyHeaders'];
    vars.displayPage = vars.displayPage || 0;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        takeAssessment.userPreview(res.data.getSurveyHeaders, res.data.getHTMLLexicon);
        return res;
      })
      .then((res) => {
        takeAssessment.getAssesment(vars.allocHuserHassessmentID, vars.displayPage, vars.HassessmentID || undefined, res.data.getHTMLLexicon);
      })
      .catch(console.error());
  },
  MAt_reports: (recipe, hdrs, vars) => {
    recipe.methods = ['getMyReports'];
    vars.TargetUser = hdrs.HuserID;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        MAt_reports.listReports(cObj(res.data.getMyReports), res.data.getHTMLLexicon);
      })
      .catch(console.error());
  },
  userNominations: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['']);
        initPageInstance(new UserNominations(vars.allocHuserHassessmentID));
      })
      .catch(console.error());
  },
  UNt_guide: (recipe, hdrs, vars) => {
    vars.allocHuserHassessmentID = pgstr.userNominations.f_allocHuserHassessmentID;
    recipe.methods = [];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new UNtguide(vars.allocHuserHassessmentID));
      })
      .catch(console.error());
  },
  UNt_nominations: (recipe, hdrs, vars) => {
    recipe.methods = ['getNominateRules'];
    vars.allocHuserHassessmentID = pgstr.userNominations.f_allocHuserHassessmentID;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new UNt_nominations(vars.allocHuserHassessmentID), res.data.getNominateRules[0]);
        // eListen(
        //   "nom1",
        //   () => {
        //     UNt_nominations.selectNom("nom1");
        //   },
        //   "click"
        // );
        // eListen(
        //   "nom2",
        //   () => {
        //     UNt_nominations.selectNom("nom2");
        //   },
        //   "click"
        // );
        // eListen(
        //   "nom3",
        //   () => {
        //     UNt_nominations.selectNom("nom3");
        //   },
        //   "click"
        // );
        // eListen(
        //   "nom4",
        //   () => {
        //     UNt_nominations.selectNom("nom4");
        //   },
        //   "click"
        // );
        // eListen(
        //   "nom5",
        //   () => {
        //     UNt_nominations.selectNom("nom5");
        //   },
        //   "click"
        // );
        // eListen(
        //   "submitNomSection",
        //   () => {
        //     pgstr.UNt_nominations.submitAll();
        //   },
        //   "click"
        // );
      })
      .catch(console.error());
  },
  UNt_history: (recipe, hdrs, vars) => {
    vars.allocHuserHassessmentID = pgstr.userNominations.f_allocHuserHassessmentID;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new UNthistory(vars.allocHuserHassessmentID));
      })
      .catch(console.error());
  },
  userSelections: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['']);
        initPageInstance(new UserSelections(vars.allocHuserHassessmentID));
      })
      .catch(console.error());
  },
  USt_guide: (recipe, hdrs, vars) => {
    vars.allocHuserHassessmentID = pgstr.userSelections.f_allocHuserHassessmentID;
    recipe.methods = [];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new UStguide(vars.allocHuserHassessmentID));
      })
      .catch(console.error());
  },
  USt_selections: (recipe, hdrs, vars) => {
    recipe.methods = ['getNominateRules', 'getChooseRateeRules'];
    vars.allocHuserHassessmentID = pgstr.userSelections.f_allocHuserHassessmentID;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new USt_selections(vars.allocHuserHassessmentID), res.data);
        eListen(
          'nom1',
          () => {
            UNt_nominations.selectNom('nom1');
          },
          'click'
        );
        eListen(
          'nom2',
          () => {
            UNt_nominations.selectNom('nom2');
          },
          'click'
        );
        eListen(
          'nom3',
          () => {
            UNt_nominations.selectNom('nom3');
          },
          'click'
        );
        eListen(
          'nom4',
          () => {
            UNt_nominations.selectNom('nom4');
          },
          'click'
        );
        eListen(
          'nom5',
          () => {
            UNt_nominations.selectNom('nom5');
          },
          'click'
        );
        eListen(
          'submitNomSection',
          () => {
            pgstr.UNt_nominations.submitAll();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  myDevelopment: (recipe, hdrs, vars) => {
    recipe.methods = ['getAllocatedIDPElements', 'getHTMLLexicon'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        myDevelopment.devPage(res.data, res.data.getHTMLLexicon);
      })
      .catch(console.error());
  },
  eFeedback: (recipe, hdrs, vars) => {
    vars.HclientID = getCookie('userClientID');
    vars.applicationID = 0;
    vars.happlicationID = 0;
    recipe.methods = ['getAllocatedHuserHcourse', 'getAdditionalServicesAllocated'];
    //getAdditionalServicesAllocated
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        eFeedback.buildeFeedback(res.data);
        eListen(
          'vid1',
          () => {
            eFeedback.openVid(1);
          },
          'click'
        );
        eListen(
          'vid2',
          () => {
            eFeedback.openVid(2);
          },
          'click'
        );
        eListen(
          'closeVid',
          () => {
            eFeedback.closeVid();
          },
          'click'
        );
        eListen(
          'closeVid2',
          () => {
            eFeedback.closeVid();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  // Jobs
  recordJob: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new RecordJob());
        eListen(
          'recordJobBtn',
          () => {
            RecordJob.recordJob();
          },
          'click'
        );
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'addJobObj',
          () => {
            RecordJob.addJobObjective();
          },
          'click'
        );
        eListen(
          'professional',
          () => {
            notEmptyVal('professional');
          },
          'change'
        );
        eListen(
          'costC',
          () => {
            notEmptyVal('costC');
          },
          'blur'
        );
        eListen(
          'costC_C',
          () => {
            notEmptyVal('costC_C');
          },
          'blur'
        );
        eListen(
          'jobNum',
          () => {
            notEmptyVal('jobNum');
          },
          'keyup'
        );
        eListen(
          'job_T',
          () => {
            notEmptyVal('job_T');
          },
          'blur'
        );
        eListen(
          'job_T_C',
          () => {
            notEmptyVal('job_T_C');
          },
          'blur'
        );
        eListen(
          'job_L',
          () => {
            notEmptyVal('job_L');
          },
          'blur'
        );
        eListen(
          'job_L_C',
          () => {
            notEmptyVal('job_L_C');
          },
          'blur'
        );
        eListen(
          'job_G',
          () => {
            notEmptyVal('job_G');
          },
          'blur'
        );
        eListen(
          'job_G_C',
          () => {
            notEmptyVal('job_G_C');
          },
          'blur'
        );
        eListen(
          'job_F',
          () => {
            notEmptyVal('job_F');
          },
          'blur'
        );
        eListen(
          'job_F_C',
          () => {
            notEmptyVal('job_F_C');
          },
          'blur'
        );
        eListen(
          'funct_A',
          () => {
            notEmptyVal('funct_A');
          },
          'blur'
        );
        eListen(
          'funct_A_C',
          () => {
            notEmptyVal('funct_A_C');
          },
          'blur'
        );
        eListen(
          'depart',
          () => {
            notEmptyVal('depart');
          },
          'blur'
        );
        eListen(
          'depart_C',
          () => {
            notEmptyVal('depart_C');
          },
          'blur'
        );
        eListen(
          'job_P',
          () => {
            notEmptyVal('job_P');
          },
          'blur'
        );
        eListen(
          'job_P_C',
          () => {
            notEmptyVal('job_P_C');
          },
          'blur'
        );
        eListen(
          'job_O_C',
          () => {
            notEmptyVal('job_O_C');
          },
          'blur'
        );
        eListen(
          'line_M',
          () => {
            notEmptyVal('line_M');
          },
          'blur'
        );
        eListen(
          'fName',
          () => {
            notEmptyVal('fName');
          },
          'keyup'
        );
        eListen(
          'sName',
          () => {
            notEmptyVal('sName');
          },
          'keyup'
        );
        eListen(
          'email',
          () => {
            eAddressVal('email');
          },
          'keyup'
        );
        eListen(
          'recruiter',
          () => {
            notEmptyVal('recruiter');
          },
          'blur'
        );
        eListen(
          'R_fName',
          () => {
            notEmptyVal('R_fName');
          },
          'keyup'
        );
        eListen(
          'R_sName',
          () => {
            notEmptyVal('R_sName');
          },
          'keyup'
        );
        eListen(
          'R_email',
          () => {
            eAddressVal('R_email');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  manageJobs: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new ManageJobs());
        eListen(
          'searchFilters',
          () => {
            ManageJobs.showSearchFilters();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  editJobs: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection([]);
        // directTab(vars);
        initPageInstance(new EditJobs(vars));
        id('HjobID').value = vars.HjobID;
        id('hclientID').value = vars.hclientID;
        eListen(
          'MJt_reports',
          () => {
            changeTabN('MJt_reports');
          },
          'click'
        );
        eListen(
          'MJt_ass',
          () => {
            changeTabN('MJt_ass');
          },
          'click'
        );
        eListen(
          'MJt_comAss',
          () => {
            changeTabN('MJt_comAss');
          },
          'click'
        );
        eListen(
          'MJt_applications',
          () => {
            changeTabN('MJt_applications');
          },
          'click'
        );
        eListen(
          'MJt_jobSpecs',
          () => {
            changeTabN('MJt_jobSpecs');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  MJt_jobSpecs: (recipe, hdrs, vars) => {
    vars.hclientID = id('hclientID').value;
    vars.HjobID = id('HjobID').value;
    recipe.methods = ['getJobsSpecDets', 'GAPJobTitle', 'GAPJobObjectives', 'GAPJobPurpose', 'GAPJobLineManager', 'GAPJobDivision', 'GAPJobFunctionArea', 'GAPJobFamilyLevel', 'GAPJobGrade', 'GAPPositionLevel', 'GAPJobProfessional', 'GAPCostCenter', 'GAPJobRecruiter', 'getClientDetails']; //'getJobDocuments', getJobProfs
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MJtJobSpecs(vars), res.data);
        eListen(
          'addProfDiv',
          () => {
            MJtJobSpecs.addProf();
          },
          'click'
        );
        eListen(
          'addJobObj',
          () => {
            RecordJob.addJobObjective();
          },
          'click'
        );
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'cloneBtn',
          () => {
            MJtJobSpecs.cloneJob();
          },
          'click'
        );
        eListen(
          'updateJobBtn',
          () => {
            MJtJobSpecs.updateJob();
          },
          'click'
        );
        eListen(
          'professional',
          () => {
            notEmptyVal('professional');
          },
          'change'
        );
        eListen(
          'job_T',
          () => {
            notEmptyVal('job_T');
          },
          'blur'
        );
        eListen(
          'job_T_C',
          () => {
            notEmptyVal('job_T_C');
          },
          'blur'
        );
        eListen(
          'job_L',
          () => {
            notEmptyVal('job_L');
          },
          'blur'
        );
        eListen(
          'job_L_C',
          () => {
            notEmptyVal('job_L_C');
          },
          'blur'
        );
        eListen(
          'job_G',
          () => {
            notEmptyVal('job_G');
          },
          'blur'
        );
        eListen(
          'job_G_C',
          () => {
            notEmptyVal('job_G_C');
          },
          'blur'
        );
        eListen(
          'job_F',
          () => {
            notEmptyVal('job_F');
          },
          'blur'
        );
        eListen(
          'job_F_C',
          () => {
            notEmptyVal('job_F_C');
          },
          'blur'
        );
        eListen(
          'funct_A',
          () => {
            notEmptyVal('funct_A');
          },
          'blur'
        );
        eListen(
          'funct_A_C',
          () => {
            notEmptyVal('funct_A_C');
          },
          'blur'
        );
        eListen(
          'depart',
          () => {
            notEmptyVal('depart');
          },
          'blur'
        );
        eListen(
          'depart_C',
          () => {
            notEmptyVal('depart_C');
          },
          'blur'
        );
        eListen(
          'costC',
          () => {
            notEmptyVal('costC');
          },
          'blur'
        );
        eListen(
          'costC_C',
          () => {
            notEmptyVal('costC_C');
          },
          'blur'
        );
        eListen(
          'job_P',
          () => {
            notEmptyVal('job_P');
          },
          'blur'
        );
        eListen(
          'job_P_C',
          () => {
            notEmptyVal('job_P_C');
          },
          'blur'
        );
        eListen(
          'job_O',
          () => {
            notEmptyVal('job_O');
          },
          'blur'
        );
        eListen(
          'job_O_C',
          () => {
            notEmptyVal('job_O_C');
          },
          'blur'
        );
        eListen(
          'line_M',
          () => {
            notEmptyVal('line_M');
          },
          'blur'
        );
        eListen(
          'recruiter',
          () => {
            notEmptyVal('recruiter');
          },
          'blur'
        );
        eListen(
          'jobNum',
          () => {
            notEmptyVal('jobNum');
          },
          'keyup'
        );
        eListen(
          'fName',
          () => {
            notEmptyVal('fName');
          },
          'keyup'
        );
        eListen(
          'sName',
          () => {
            notEmptyVal('sName');
          },
          'keyup'
        );
        eListen(
          'email',
          () => {
            eAddressVal('email');
          },
          'keyup'
        );
        eListen(
          'R_fName',
          () => {
            notEmptyVal('R_fName');
          },
          'keyup'
        );
        eListen(
          'R_sName',
          () => {
            notEmptyVal('R_sName');
          },
          'keyup'
        );
        eListen(
          'R_email',
          () => {
            eAddressVal('R_email');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  MJt_applications: (recipe, hdrs, vars) => {
    vars.hclientID = id('hclientID').value;
    vars.HjobID = id('HjobID').value;
    recipe.methods = ['getApplicationsPerJob'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MJtApplications(vars), res.data);
        eListen(
          'uploadApps',
          () => {
            MJtApplications.uploadApps();
          },
          'click'
        );
        eListen(
          'f_profile',
          () => {
            MJtApplications.addApplicant();
          },
          'click'
        );
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'bulkUploadToggle',
          () => {
            MJtApplications.bulkToggle();
          },
          'change'
        );
        eListen(
          'upload',
          () => {
            MJtApplications.displayFile(id('upload').files[0]);
          },
          'change'
        );
        eListen(
          'fName',
          () => {
            notEmptyVal('fName');
          },
          'keyup'
        );
        eListen(
          'sName',
          () => {
            notEmptyVal('sName');
          },
          'keyup'
        );
        eListen(
          'email',
          () => {
            eAddressVal('email');
          },
          'keyup'
        );
        eListen(
          'f_cellNr',
          () => {
            mobNumVal('f_cellNr');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  MJt_comAss: (recipe, hdrs, vars) => {
    vars.hclientID = id('hclientID').value;
    vars.HjobID = id('HjobID').value;
    recipe.methods = ['getDDCategoryGroupPerClient', 'getCompetencyByJob', 'getAssessmentsPerJob', 'getAssessmentsPerJobCompetencies', 'getReportsPerJob', 'getReportsForJob'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MJtCom(vars), res.data);
        eListen(
          'addCompetency',
          () => {
            MJtCom.addCompetency();
          },
          'click'
        );
        eListen(
          'catGroup',
          () => {
            MJtCom.linkCompetency();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  MJt_ass: (recipe, hdrs, vars) => {
    vars.hclientID = id('hclientID').value;
    vars.HjobID = id('HjobID').value;
    recipe.methods = ['getDDCategoryGroupPerClient', 'getCompetencyByJob', 'getAssessmentsPerJob', 'getAssessmentsPerJobCompetencies', 'getReportsPerJob', 'getReportsForJob', 'getBatteriesForJob', 'getBatteriesPerJob'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MJtAss(vars), res.data);
        eListen(
          'compentenciesPage',
          () => {
            changeTabN('MJt_comAss');
          },
          'click'
        );
        eListen(
          'addBattery',
          () => {
            MJtAss.addBattery();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  MJt_reports: (recipe, hdrs, vars) => {
    vars.hclientID = id('hclientID').value;
    vars.HjobID = id('HjobID').value;
    recipe.methods = ['getDDCategoryGroupPerClient', 'getCompetencyByJob', 'getAssessmentsPerJob', 'getAssessmentsPerJobCompetencies', 'getReportsPerJob', 'getReportsForJob'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MJtReports(vars), res.data);
        // id="allocReportBtn"onclick="MJtReports.allocReport()"
        eListen(
          'allocReportBtn',
          () => {
            MJtReports.allocReport();
          },
          'click'
        );
        eListen(
          'compAssBtn',
          () => {
            MJtReports.allocateComAss();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  //Manage Users
  manageUsers: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        eListen(
          'MUt_users',
          () => {
            changeTabN('MUt_users');
          },
          'click'
        );
        eListen(
          'MUt_advSearch',
          () => {
            changeTabN('MUt_advSearch');
          },
          'click'
        );
        eListen(
          'MUt_compareUsers',
          () => {
            changeTabN('MUt_compareUsers');
          },
          'click'
        );
        eListen(
          'leftScroll',
          () => {
            leftScroll();
          },
          'click'
        );
        eListen(
          'rightScroll',
          () => {
            rightScroll();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  MUt_users: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect', 'selectGroup', 'addToUserGroupBtn']);
        initPageInstance(new MUtUsers());
        if (id('clientDD').value !== '') {
          // manageUsers.getGroups();
          hide('activeYN');
        }
        eListen(
          'compareBtn',
          () => {
            MUtUsers.compareUsers();
          },
          'click'
        );
        eListen(
          'searchFilters',
          () => {
            MUtUsers.searchFilters();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  MUt_advSearch: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect', 'selectGroup', 'addToUserGroupBtn']);
        initPageInstance(new MUt_AdvSearch(vars));
        if (id('clientDD').value !== '') {
          hide('activeYN');
        }
        eListen(
          'assessmentsDD',
          () => {
            notEmptyVal('assessmentsDD');
          },
          'change'
        );
        eListen(
          'raterRateeDD',
          () => {
            notEmptyVal('raterRateeDD');
          },
          'change'
        );
        eListen(
          'statusDD',
          () => {
            notEmptyVal('statusDD');
          },
          'change'
        );
      })
      .catch(console.error());
  },
  assignAssessment: (recipe, hdrs, vars) => {
    recipe.methods = ['getAssessmentsPerClient', 'getDDraterRelationship', 'getUsers'];
    vars.roleList = 3;
    if (vars.editUserID) {
      recipe.methods.push('getUserDets');
      vars.allocHuserID = vars.editUserID;
      vars.detailsUserID = vars.editUserID;
    }
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection([]);
        initPageInstance(new assAssessment(vars), res.data);
        eListen(
          'AssesmentDD',
          () => {
            assAssessment.linkAssesment();
            notEmptyVal('AssesmentDD');
          },
          'change'
        );
        eListen(
          'assDate',
          () => {
            assAssessment.termDate('assDate');
            notEmptyVal('assDate');
          },
          'change'
        );
        eListen(
          'addRatersValue',
          () => {
            assAssessment.addRater('addRatersValue');
          },
          'change'
        );
        eListen(
          'selfSelectRateeValue',
          () => {
            assAssessment.selfSelect();
          },
          'change'
        );
        eListen(
          'linkSimple',
          () => {
            assAssessment.linkSimple();
          },
          'click'
        );
        eListen(
          'targetUserGroupDDNominate',
          () => {
            assAssessment.newOption('targetUserGroupDDNominate');
          },
          'change'
        );
        eListen(
          'addNewRatersValue',
          () => {
            assAssessment.addRater('addNewRatersValue');
          },
          'click'
        );
        eListen(
          'addRatersPrescribed',
          () => {
            assAssessment.relationships();
          },
          'change'
        );
        eListen(
          'addRatersOpen',
          () => {
            assAssessment.relationships();
          },
          'change'
        );
        eListen(
          'addApproverBtns',
          () => {
            assAssessment.addApprover();
          },
          'click'
        );
        eListen(
          'nomDate',
          () => {
            assAssessment.termDate('nomDate');
          },
          'change'
        );
        eListen(
          'linkNominations',
          () => {
            assAssessment.linkNominations();
          },
          'click'
        );
        eListen(
          'targetUserGroupDDChoose',
          () => {
            assAssessment.newOption('targetUserGroupDDChoose');
          },
          'change'
        );
        eListen(
          'cRateePrescribed',
          () => {
            assAssessment.cRateeRelationships();
          },
          'change'
        );
        eListen(
          'cRateeOpen',
          () => {
            assAssessment.cRateeRelationships();
          },
          'change'
        );
        eListen(
          'selDate',
          () => {
            assAssessment.termDate('selDate');
          },
          'change'
        );
        eListen(
          'linkChoices',
          () => {
            assAssessment.linkChoices();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  //Add User
  addUser: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']); //, 'company'
        initPageInstance(new addUser());
        eListen(
          'AUt_addUser',
          () => {
            changeTabN('AUt_addUser');
          },
          'click'
        );
        eListen(
          'AUt_bulkUser',
          () => {
            changeTabN('AUt_bulkUser');
          },
          'click'
        );
        eListen(
          'leftScroll',
          () => {
            leftScroll();
          },
          'click'
        );
        eListen(
          'rightScroll',
          () => {
            rightScroll();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  AUt_addUser: (recipe, hdrs, vars) => {
    recipe.methods = ['getDDroles'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new AUt_addUser(), res.data);

        buildDropDownMain(cObj(res.data.getDDroles), 'roleDD', 'DDROLEID', 'DESCRIPTION');
        eListen(
          'fname',
          () => {
            notEmptyVal('fname');
          },
          'keyup'
        );
        eListen(
          'sname',
          () => {
            notEmptyVal('sname');
          },
          'keyup'
        );
        eListen(
          'f_cellNr',
          () => {
            mobNumVal('f_cellNr');
          },
          'keyup'
        );
        eListen(
          'emailAddress',
          () => {
            eAddressVal('emailAddress');
          },
          'keyup'
        );
        eListen(
          'employeeNo',
          () => {
            notEmptyVal('employeeNo');
          },
          'keyup'
        );
        eListen(
          'roleDD',
          () => {
            notEmptyVal('roleDD');
          },
          'change'
        );
        eListen(
          'hideFeedback',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'f_profile',
          () => {
            AUt_addUser.addSingleUser();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  AUt_bulkUser: (recipe, hdrs, vars) => {
    recipe.methods = ['getDDroles'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        clearAllDataS('uploadedUsers');
        toggleSelectsection(['clientSelect', 'selectGroup']);
        buildDropDownMain(cObj(res.data.getDDroles), 'bulkRoleDD', 'DDROLEID', 'DESCRIPTION');

        initPageInstance(new AUt_bulkUser());
        eListen(
          'uploadUserBtn',
          () => {
            AUt_bulkUser.uploadUsers();
          },
          'click'
        );
        eListen(
          'bulkRoleDD',
          () => {
            notEmptyVal('bulkRoleDD');
          },
          'change'
        );
        eListen(
          'excelUpload',
          () => {
            AUt_bulkUser.excelUpload();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  // AUt_linkBulkUser page not complete -> turned off
  // AUt_linkBulkUser: (recipe, hdrs, vars) => {
  //   SWITCHBOX(recipe, hdrs, vars)
  //     .then(() => {
  //       toggleSelectsection(['clientSelect']);
  //       initPageInstance(new AUt_linkBulkUser())
  //     })
  //     .catch(console.error())
  // },
  // Compare Users
  MUt_compareUsers: (recipe, hdrs, vars) => {
    recipe.tabs = 0;
    recipe.methods = ['getUserMergeDets'];
    vars.hclientID = id('clientDD').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        initPageInstance(new MUt_CompareUsers(vars), res.data);
        hide('tabList');
        eListen(
          'profile_0_selectedCU',
          () => {
            MUt_CompareUsers.selectuserProfile('profile_0_selectedCU');
          },
          'click'
        );
        eListen(
          'ppic_0_box',
          () => {
            MUt_CompareUsers.selectuserProfileImage('ppic_0_box');
          },
          'click'
        );
        eListen(
          'fname_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('fname_0_box');
          },
          'click'
        );
        eListen(
          'sname_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('sname_0_box');
          },
          'click'
        );
        eListen(
          'mobile_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('mobile_0_box');
          },
          'click'
        );
        eListen(
          'email_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('email_0_box');
          },
          'click'
        );
        eListen(
          'IDNum_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('IDNum_0_box');
          },
          'click'
        );
        eListen(
          'gender_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('gender_0_box');
          },
          'click'
        );
        eListen(
          'eth_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('eth_0_box');
          },
          'click'
        );
        eListen(
          'nat_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('nat_0_box');
          },
          'click'
        );
        eListen(
          'role_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('role_0_box');
          },
          'click'
        );
        eListen(
          'job_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('job_0_box');
          },
          'click'
        );
        eListen(
          'foreignNat_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('foreignNat_0_box');
          },
          'click'
        );
        eListen(
          'passportNum_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('passportNum_0_box');
          },
          'click'
        );
        eListen(
          'workPermRSA_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('workPermRSA_0_box');
          },
          'click'
        );
        eListen(
          'dob_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('dob_0_box');
          },
          'click'
        );
        eListen(
          'empID_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('empID_0_box');
          },
          'click'
        );
        eListen(
          'linkedInURL_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('linkedInURL_0_box');
          },
          'click'
        );
        eListen(
          'cntryofO_0_box',
          () => {
            MUt_CompareUsers.selectuserItem('cntryofO_0_box');
          },
          'click'
        );
        eListen(
          'profile_1_selectedCU',
          () => {
            MUt_CompareUsers.selectuserProfile('profile_1_selectedCU');
          },
          'click'
        );
        eListen(
          'ppic_1_box',
          () => {
            MUt_CompareUsers.selectuserProfileImage('ppic_1_box');
          },
          'click'
        );
        eListen(
          'fname_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('fname_1_box');
          },
          'click'
        );
        eListen(
          'sname_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('sname_1_box');
          },
          'click'
        );
        eListen(
          'mobile_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('mobile_1_box');
          },
          'click'
        );
        eListen(
          'email_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('email_1_box');
          },
          'click'
        );
        eListen(
          'IDNum_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('IDNum_1_box');
          },
          'click'
        );
        eListen(
          'gender_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('gender_1_box');
          },
          'click'
        );
        eListen(
          'eth_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('eth_1_box');
          },
          'click'
        );
        eListen(
          'nat_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('nat_1_box');
          },
          'click'
        );
        eListen(
          'role_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('role_1_box');
          },
          'click'
        );
        eListen(
          'job_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('job_1_box');
          },
          'click'
        );
        eListen(
          'foreignNat_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('foreignNat_1_box');
          },
          'click'
        );
        eListen(
          'passportNum_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('passportNum_1_box');
          },
          'click'
        );
        eListen(
          'workPermRSA_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('workPermRSA_1_box');
          },
          'click'
        );
        eListen(
          'dob_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('dob_1_box');
          },
          'click'
        );
        eListen(
          'empID_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('empID_1_box');
          },
          'click'
        );
        eListen(
          'linkedInURL_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('linkedInURL_1_box');
          },
          'click'
        );
        eListen(
          'cntryofO_1_box',
          () => {
            MUt_CompareUsers.selectuserItem('cntryofO_1_box');
          },
          'click'
        );
        eListen(
          'profile_2_selectedCU',
          () => {
            MUt_CompareUsers.selectuserProfile('profile_2_selectedCU');
          },
          'click'
        );
        eListen(
          'ppic_2_box',
          () => {
            MUt_CompareUsers.selectuserProfileImage('ppic_2_box');
          },
          'click'
        );
        eListen(
          'fname_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('fname_2_box');
          },
          'click'
        );
        eListen(
          'sname_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('sname_2_box');
          },
          'click'
        );
        eListen(
          'mobile_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('mobile_2_box');
          },
          'click'
        );
        eListen(
          'email_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('email_2_box');
          },
          'click'
        );
        eListen(
          'IDNum_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('IDNum_2_box');
          },
          'click'
        );
        eListen(
          'gender_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('gender_2_box');
          },
          'click'
        );
        eListen(
          'eth_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('eth_2_box');
          },
          'click'
        );
        eListen(
          'nat_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('nat_2_box');
          },
          'click'
        );
        eListen(
          'role_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('role_2_box');
          },
          'click'
        );
        eListen(
          'job_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('job_2_box');
          },
          'click'
        );
        eListen(
          'foreignNat_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('foreignNat_2_box');
          },
          'click'
        );
        eListen(
          'passportNum_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('passportNum_2_box');
          },
          'click'
        );
        eListen(
          'workPermRSA_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('workPermRSA_2_box');
          },
          'click'
        );
        eListen(
          'dob_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('dob_2_box');
          },
          'click'
        );
        eListen(
          'empID_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('empID_2_box');
          },
          'click'
        );
        eListen(
          'linkedInURL_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('linkedInURL_2_box');
          },
          'click'
        );
        eListen(
          'cntryofO_2_box',
          () => {
            MUt_CompareUsers.selectuserItem('cntryofO_2_box');
          },
          'click'
        );
        eListen(
          'MUt_mergeUsers',
          () => {
            MUt_CompareUsers.mergeUsers();
          },
          'click'
        );
      })
      .catch(console.error());
  },

  // Edit User
  editUser: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        id('editUserID').value = vars.editUserID;
        id('hclientID').value = vars.HclientID;
        toggleSelectsection(['page']); //company
        eListen(
          'leftScroll',
          () => {
            leftScroll();
          },
          'click'
        );
        eListen(
          'EUt_profile',
          () => {
            changeTabN('EUt_profile');
          },
          'click'
        );
        eListen(
          'EUt_messageBox',
          () => {
            changeTabN('EUt_messageBox');
          },
          'click'
        );
        eListen(
          'EUt_navigation',
          () => {
            changeTabN('EUt_navigation');
          },
          'click'
        );
        eListen(
          'EUt_allocateClient',
          () => {
            changeTabN('EUt_allocateClient');
          },
          'click'
        );
        eListen(
          'EUt_userGroups',
          () => {
            changeTabN('EUt_userGroups');
          },
          'click'
        );
        eListen(
          'EUt_userAssessments',
          () => {
            changeTabN('EUt_userAssessments');
          },
          'click'
        );
        eListen(
          'EUt_reports',
          () => {
            changeTabN('EUt_reports');
          },
          'click'
        );
        eListen(
          'EUt_results',
          () => {
            changeTabN('EUt_results');
          },
          'click'
        );
        eListen(
          'EUt_userApprovals',
          () => {
            changeTabN('EUt_userApprovals');
          },
          'click'
        );
        eListen(
          'EUt_developmentPlan',
          () => {
            changeTabN('EUt_developmentPlan');
          },
          'click'
        );
        eListen(
          'EUt_projects',
          () => {
            changeTabN('EUt_projects');
          },
          'click'
        );
        eListen(
          'EUt_LMS',
          () => {
            changeTabN('EUt_LMS');
          },
          'click'
        );
        eListen(
          'EUt_busEntAlloc',
          () => {
            changeTabN('EUt_busEntAlloc');
          },
          'click'
        );
        eListen(
          'rightScroll',
          () => {
            rightScroll();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  EUt_profile: (recipe, hdrs, vars) => {
    vars.detailsUserID = id('editUserID').value;
    vars.HclientID = id('clientDD').value;
    recipe.methods = ['getUserDets', 'getDDroles', 'getLoggedInhomePageOptions'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        id('pencil').src = editPencilIcon;
        initPageInstance(new EUt_profile(vars), res.data);
        eListen(
          'hideFeedback',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'viewLIHPbtn',
          () => {
            EUt_profile.viewLIHP('userLihpDD');
          },
          'click'
        );
        eListen(
          'closePopup',
          () => {
            hide('viewLIHP_popupdiv');
          },
          'click'
        );
        eListen(
          'f_profile',
          () => {
            EUt_profile.updateUserProfile();
          },
          'click'
        );
        eListen(
          'changePass',
          () => {
            EUt_profile.changePassBox();
          },
          'click'
        );
        eListen(
          'image_file',
          () => {
            UPt_profile.selectDP();
          },
          'change'
        );
        eListen(
          'userRoleDD',
          () => {
            notEmptyVal('userRoleDD');
          },
          'change'
        );
        eListen(
          'userLihpDD',
          () => {
            notEmptyVal('userLihpDD');
          },
          'change'
        );
        eListen(
          'f_firstName',
          () => {
            notEmptyVal('f_firstName');
          },
          'keyup'
        );
        eListen(
          'f_surname',
          () => {
            notEmptyVal('f_surname');
          },
          'keyup'
        );
        eListen(
          'f_email',
          () => {
            eAddressVal('f_email');
          },
          'keyup'
        );
        eListen(
          'f_cellNr',
          () => {
            mobNumVal('f_cellNr');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  EUt_messageBox: (recipe, hdrs, vars) => {
    vars.editUserID = id('editUserID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        initPageInstance(new EUt_messageBox(vars));
        eListen(
          'emailBlock',
          () => {
            EUt_messageBox.changeSubTab('emailBlock');
          },
          'click'
        );
        eListen(
          'smsBlock',
          () => {
            EUt_messageBox.changeSubTab('smsBlock');
          },
          'click'
        );
        eListen(
          'inbox',
          () => {
            EUt_messageBox.inboxSent('inbox');
          },
          'click'
        );
        eListen(
          'sent',
          () => {
            EUt_messageBox.inboxSent('sent');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  EUt_navigation: (recipe, hdrs, vars) => {
    vars.detailsUserID = id('editUserID').value;
    recipe.methods = ['getUserMenuOptions'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_navigation(vars), res.data);
        // editUser.navPage(res.data.getUserMenuOptions)
      })
      .catch(console.error());
  },
  EUt_allocateClient: (recipe, hdrs, vars) => {
    vars.editUserID = id('editUserID').value;
    recipe.methods = ['getAllocatedClients'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_allocateClient(vars), res.data);
        eListen(
          'f_allocateClients',
          () => {
            EUt_allocateClient.updateAllocatedClients();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  EUt_userGroups: (recipe, hdrs, vars) => {
    vars.groupUserID = id('editUserID').value;
    vars.targetUserID = id('editUserID').value;
    vars.HclientID = id('hclientID').value;
    recipe.methods = ['getGroupsPerHuser', 'getUserGroups', 'getTeams', 'getTeamsPerUser'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_userGroups(vars), res.data);
        eListen(
          'groupsBlock',
          () => {
            EUt_userGroups.changeSubTab('groupsBlock');
          },
          'click'
        );
        eListen(
          'teamsBlock',
          () => {
            EUt_userGroups.changeSubTab('teamsBlock');
          },
          'click'
        );
        eListen(
          'addToUserGroup2',
          () => {
            EUt_userGroups.addToUserGroup();
          },
          'click'
        );
        eListen(
          'addTeamMembers2',
          () => {
            EUt_userGroups.addTeamMembers();
          },
          'click'
        );
        eListen(
          'groupDD2',
          () => {
            notEmptyVal('groupDD2');
            EUt_userGroups.newGroup();
          },
          'change'
        );
        eListen(
          'teamDD2',
          () => {
            notEmptyVal('teamDD2');
          },
          'change'
        );
      })
      .catch(console.error());
  },
  EUt_userAssessments: (recipe, hdrs, vars) => {
    vars.participantID = id('editUserID').value;
    recipe.methods = ['getParticipantReview'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_userAssessments(vars), res.data);
      })
      .catch(console.error());
  },
  EUt_reports: (recipe, hdrs, vars) => {
    vars.TargetUser = id('editUserID').value;
    recipe.methods = ['getReportTypesForUserUpload', 'getReportsAllocatedToUser'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_reports(vars), res.data);
        eListen(
          'EUt_rmvFile',
          () => {
            EUt_reports.rmvFile();
          },
          'click'
        );
        eListen(
          'uploadReportBtn',
          () => {
            EUt_reports.uploadReport();
          },
          'click'
        );
        eListen(
          //mikeee
          'reportType',
          () => {
            notEmptyVal('reportType');
          },
          'change'
        );
        eListen(
          'assResultStartDate',
          () => {
            notEmptyVal('assResultStartDate');
          },
          'change'
        );
        eListen(
          'reportFile',
          () => {
            EUt_reports.fileSelected();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  EUs_MCPAscan: (recipe, hdrs, vars) => {
    recipe.methods = ['getMCPAScanMeasures'];

    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        buildDropDownMain(res.data.getMCPAScanMeasures, 'currentCapScan', 'DDMCPAScanID', 'shortDesc');
        buildDropDownMain(res.data.getMCPAScanMeasures, 'currentCapScan2', 'DDMCPAScanID', 'shortDesc');

        // buildDropDownMain(res.data.getMCPAScanMeasures, 'futureDevScan', 'DDMCPAScanID', 'shortDesc');
        eListen(
          'nextStep1Btn',
          () => {
            uploadResults.uploadMCPAScan();
          },
          'click'
        );
        eListen(
          'goBackBtn',
          () => {
            uploadResults.goBack(2);
            hide('resultsNote0');
          },
          'click'
        );
        eListen(
          'nextStep1',
          () => {
            uploadResults.step1Next();
            uploadResults.reVal('inputStep00');
          },
          'click'
        );
        eListen(
          'currentCapScan',
          () => {
            notEmptyVal('currentCapScan');
            uploadResults.futureDevDD('currentCapScan', 'futureDevScan');
          },
          'change'
        );
        eListen(
          'futureDevScan',
          () => {
            notEmptyVal('futureDevScan');
          },
          'change'
        );
        eListen(
          'currentCapScan2',
          [
            () => {
              notEmptyVal('currentCapScan2');
              uploadResults.futureDevDD('currentCapScan2', 'futureDevScan2');
              uploadResults.ddCompare('currentCapScan');
            },
            () => {
              uploadResults.ddCompare('currentCapScan');
            },
          ],
          ['change', 'blur']
        ); //checked
        eListen(
          'futureDevScan2',
          [
            () => {
              notEmptyVal('futureDevScan2');
              uploadResults.ddCompare('futureDevScan');
            },
            () => {
              uploadResults.ddCompare('futureDevScan');
            },
          ],
          ['change', 'blur']
        ); //checked
      })
      .catch(console.error());
  },
  EUs_MCPA: (recipe, hdrs, vars) => {
    recipe.methods = ['getMCPAMeasures'];

    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        buildDropDownMain(res.data.getMCPAMeasures, 'currentCap', 'DDMCPAID', 'shortDesc');
        buildDropDownMain(res.data.getMCPAMeasures, 'futureDev', 'DDMCPAID', 'shortDesc');
        buildDropDownMain(res.data.getMCPAMeasures, 'currentCap2', 'DDMCPAID', 'shortDesc');
        buildDropDownMain(res.data.getMCPAMeasures, 'futureDev2', 'DDMCPAID', 'shortDesc');
        eListen(
          'nextStep1Btn',
          () => {
            uploadResults.uploadMCPA();
          },
          'click'
        );
        eListen(
          'hideResultsNoteBtn',
          () => {
            uploadResults.goBack(2);
            hide('resultsNote0');
          },
          'click'
        );
        eListen(
          'nextStep1',
          () => {
            uploadResults.step1Next();
            uploadResults.reVal('inputStep00');
          },
          'click'
        );
        eListen(
          'currentCap',
          () => {
            notEmptyVal('currentCap');
          },
          'change'
        );
        eListen(
          'futureDev',
          () => {
            notEmptyVal('futureDev');
          },
          'change'
        );
        eListen(
          'currentCap2',
          [
            () => {
              notEmptyVal('currentCap2');
              uploadResults.ddCompare('currentCap');
            },
            () => {
              uploadResults.ddCompare('currentCap');
            },
          ],
          ['change', 'blur']
        ); //checked
        eListen(
          'futureDev2',
          [
            () => {
              notEmptyVal('futureDev2');
              uploadResults.ddCompare('futureDev');
            },
            () => {
              uploadResults.ddCompare('futureDev');
            },
          ],
          ['change', 'blur']
        ); //checked
      })
      .catch(console.error());
  },
  EUs_CPP: (recipe, hdrs, vars) => {
    recipe.methods = ['getDDLevelsCPP'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        uploadResults.CPPpage(res.data.getDDLevelsCPP);
      })
      .catch(console.error());
  },
  EUt_results: (recipe, hdrs, vars) => {
    vars.targetUserID = id('editUserID').value;
    recipe.methods = ['getAllocatedAssessmentsForCompletionEvents'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_results(vars), res.data);
        eListen(
          'autoIDPBtn',
          () => {
            EUt_results.autoCreate();
          },
          'click'
        );
        eListen(
          'uploadReportBtn',
          () => {
            EUt_results.uploadReportIDP();
          },
          'click'
        );
        eListen(
          'reportFile_X',
          () => {
            EUt_reports.rmvFile('reportFile');
          },
          'click'
        );
        eListen(
          'uploadResultsBtn',
          () => {
            EUt_results.uploadResults();
          },
          'click'
        );
        eListen(
          'scoresFile_X',
          () => {
            EUt_reports.rmvFile('scoresFile');
          },
          'click'
        );
        eListen(
          'assessmentSelect',
          () => {
            notEmptyVal('assessmentSelect');
            EUt_results.assDD('assessmentSelect');
          },
          'change'
        );
        eListen(
          'scoresFile',
          () => {
            EUt_results.fileSelected('scoresFile');
          },
          'change'
        );
        eListen(
          'reportFile',
          () => {
            EUt_results.fileSelected('reportFile');
          },
          'change'
        );
      })
      .catch(console.error());
  },
  EUt_developmentPlan: (recipe, hdrs, vars) => {
    vars.candidateID = id('editUserID').value;
    vars.assetType = 'articles';
    recipe.methods = ['getDDassessmentType'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_developmentPlan(vars), res.data);
        eListen(
          'editUserIDPView',
          () => {
            EUt_developmentPlan.changeSubTab('editUserIDPView');
          },
          'click'
        );
        eListen(
          'editUserIDPAdd',
          () => {
            EUt_developmentPlan.changeSubTab('editUserIDPAdd');
          },
          'click'
        );
        eListen(
          'getDDAssessmentType',
          () => {
            EUt_developmentPlan.assFrameworkDD();
            notEmptyVal('getDDAssessmentType');
          },
          'change'
        );
        eListen(
          'getDDAssessmentFramework',
          () => {
            EUt_developmentPlan.catFormDD();
            notEmptyVal('getDDAssessmentFramework');
          },
          'change'
        );
        eListen(
          'getDDCategory',
          () => {
            EUt_developmentPlan.libAssetMeasure();
            notEmptyVal('getDDCategory');
          },
          'change'
        );
        eListen(
          'genSearch',
          () => {
            EUt_developmentPlan.filter();
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  EUt_projects: (recipe, hdrs, vars) => {
    vars.candidateID = id('editUserID').value;
    recipe.methods = ['getJVROProjectsForAlloc', 'getAllocatedProjectsToUser', 'getExternalAssessmentsForAlloc', 'getAllocatedExternalAssessmentsToUser'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_projects(vars), res.data);
        eListen(
          'EUt_allocAss',
          () => {
            EUt_projects.allocateProject('assessment');
          },
          'click'
        );
        eListen(
          'EUt_allocProject',
          () => {
            EUt_projects.allocateProject('project');
          },
          'click'
        );
        eListen(
          'jvroProject',
          () => {
            notEmptyVal('jvroProject');
          },
          'change'
        );
        eListen(
          'exAssessment',
          () => {
            notEmptyVal('exAssessment');
          },
          'change'
        );
      })
      .catch(console.error());
  },
  EUt_LMS: (recipe, hdrs, vars) => {
    vars.TargetHuserID = id('editUserID').value;
    recipe.methods = ['getAllocatedHuserHcourse'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_LMS(vars), res.data);
        eListen(
          'addEFeedback',
          () => {
            EUt_LMS.addLMSFeedback();
          },
          'click'
        );
        eListen(
          'courseTypeDD',
          () => {
            notEmptyVal('courseTypeDD');
            EUt_LMS.courseTypeDD();
          },
          'change'
        );
        eListen(
          'eFeedbackDD',
          () => {
            notEmptyVal('eFeedbackDD');
          },
          'change'
        );
      })
      .catch(console.error());
  },
  EUt_busEntAlloc: (recipe, hdrs, vars) => {
    vars.editUserID = id('editUserID').value;
    vars.HclientID = id('clientDD').value;
    vars.reportCategory = 'integratedReport';
    recipe.methods = ['getBusinessEntities', 'getReportType'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new EUt_busEntAlloc(vars), res.data);
        eListen(
          'addBusEntAlloc',
          () => {
            EUt_busEntAlloc.addBusEntAlloc();
          },
          'click'
        );
        eListen(
          'addReportViewingAlloc',
          () => {
            EUt_busEntAlloc.addReportViewingAlloc();
          },
          'click'
        );
        eListen(
          'reportViewing',
          () => {
            notEmptyVal('reportViewing');
            EUt_busEntAlloc.reportViewingChange();
          },
          'change'
        );
        eListen(
          'busEntity',
          () => {
            notEmptyVal('busEntity');
            EUt_busEntAlloc.populatelegalEnt();
          },
          'change'
        );
        eListen(
          'legalEntity',
          () => {
            notEmptyVal('legalEntity');
          },
          'change'
        );
      })
      .catch(console.error());
  },

  // Admin Applications
  adminApplications: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new AdminApplications());

        eListen(
          'currBlock',
          () => {
            applications.changeAppState('currBlock', 'o');
          },
          'click'
        );
        eListen(
          'compBlock',
          () => {
            applications.changeAppState('compBlock', 'Com');
          },
          'click'
        );
        eListen(
          'cancelBlock',
          () => {
            applications.changeAppState('cancelBlock', 'Can');
          },
          'click'
        );
        eListen(
          'feedbackBlock',
          () => {
            applications.changeAppState('feedbackBlock', 'FBT');
          },
          'click'
        );
        eListen(
          'searchFilters',
          () => {
            applications.searchFilters();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  sanitizeApplications: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    recipe.methods = ['getApplicationDetail'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection([]);
        directTab(vars);
        initPageInstance(new SanitizeApplication(vars), res.data);
        id('editUserID').value = res.data.getApplicationDetail[0].candidateID;
        id('HclientID').value = res.data.getApplicationDetail[0].HclientID;
        id('HapplicationID').value = vars.HAPPLICATIONID;
      })
      .catch(console.error());
  },
  SAt_headers: (recipe, hdrs, vars) => {
    vars = {};
    recipe.methods = ['getApplicationDetail'];
    vars.HapplicationID = id('HapplicationID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        if (res.data.getApplicationDetail[0].HclientID == 4) {
          changeSubTab('SAs_headersDISC');
        } else if (res.data.getApplicationDetail[0].HclientID == 42) {
          changeSubTab('SAs_headersAA');
        } else if (res.data.getApplicationDetail[0].GAPYN) {
          changeSubTab('SAs_headersGAP');
        } else {
          changeSubTab('SAs_headersGAP');
        }
      })
      .catch(console.error());
  },
  SAs_headersDISC: (recipe, hdrs, vars) => {
    recipe.methods = ['getApplicationDetail', 'getDDCountry', 'getDDPositionLevel', 'getDiscoveryLineManagers', 'getDDCostCenters', 'getDDdivisions', 'getDDjobfamily', 'getDiscoveryRecruiters'];
    vars.HapplicationID = id('HapplicationID').value;
    vars.HclientID = id('HclientID').value;
    vars.grouping = id('grouping').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SanitizeAppDiscHeaders(vars), res.data);
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'submitBtn',
          () => {
            SanitizeAppDiscHeaders.updateApplication();
          },
          'click'
        );
        eListen(
          'cancelBtn',
          () => {
            SanitizeAppDiscHeaders.cancelReason();
          },
          'click'
        );
        eListen(
          'preferredDate',
          () => {
            notEmptyVal('preferredDate');
          },
          'change'
        );
        eListen(
          'preferredLocation',
          () => {
            notEmptyVal('preferredLocation');
          },
          'change'
        );
        eListen(
          'purpose',
          () => {
            notEmptyVal('purpose');
          },
          'change'
        );
        eListen(
          'DDpositionLevelID',
          () => {
            notEmptyVal('DDpositionLevelID');
          },
          'change'
        );
        eListen(
          'DDjobFamilyID',
          () => {
            notEmptyVal('DDjobFamilyID');
          },
          'change'
        );
        eListen(
          'DDcostCenterID',
          () => {
            notEmptyVal('DDcostCenterID');
          },
          'change'
        );
        eListen(
          'DDdivisionID',
          () => {
            notEmptyVal('DDdivisionID');
          },
          'change'
        );
        eListen(
          'hiringManagerID',
          () => {
            notEmptyVal('hiringManagerID');
          },
          'change'
        );
        eListen(
          'recruiterID',
          () => {
            notEmptyVal('recruiterID');
          },
          'change'
        );
        eListen(
          'candidatefname',
          () => {
            notEmptyVal('');
          },
          'keyup'
        );
        eListen(
          'candidatesname',
          () => {
            notEmptyVal('candidatesname');
          },
          'keyup'
        );
        eListen(
          'candidateIDnr',
          () => {
            notEmptyVal('candidateIDnr');
          },
          'keyup'
        );
        eListen(
          'candidatePassportnr',
          () => {
            notEmptyVal('candidatePassportnr');
          },
          'keyup'
        );
        eListen(
          'candidateemailAddress',
          () => {
            eAddressVal('candidateemailAddress');
          },
          'keyup'
        );
        eListen(
          'f_cellNr',
          () => {
            mobNumVal('f_cellNr');
          },
          'keyup'
        );
        eListen(
          'positionNumber',
          () => {
            notEmptyVal('positionNumber');
          },
          'keyup'
        );
        eListen(
          'rfcNr',
          () => {
            notEmptyVal('rfcNr');
          },
          'keyup'
        );
        eListen(
          'clientcomments',
          () => {
            notEmptyVal('clientcomments');
          },
          'keyup'
        );
        eListen(
          'jobTitle',
          () => {
            notEmptyVal('jobTitle');
          },
          'keyup'
        );
        eListen(
          'c_f_positionlevel',
          () => {
            filterOptionsHandler('c_f_positionlevel', 'DDpositionLevelID');
          },
          'keyup'
        );
        eListen(
          'newjobFamilyName',
          () => {
            filterOptionsHandler('newjobFamilyName', 'DDjobFamilyID');
          },
          'keyup'
        );
        eListen(
          'newCostCenterName',
          () => {
            filterOptionsHandler('newCostCenterName', 'DDcostCenterID');
          },
          'keyup'
        );
        eListen(
          'newDivisionName',
          () => {
            filterOptionsHandler('newDivisionName', 'DDdivisionID');
          },
          'keyup'
        );
        eListen(
          'c_f_hiringManager',
          () => {
            filterOptionsHandler('c_f_hiringManager', 'hiringManagerID');
          },
          'keyup'
        );
        eListen(
          'c_f_recruiter',
          () => {
            filterOptionsHandler('c_f_recruiter', 'recruiterID');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  SAs_headersGAP: (recipe, hdrs, vars) => {
    recipe.methods = ['getApplicationDetail'];
    vars.HapplicationID = id('HapplicationID').value;
    vars.HclientID = id('HclientID').value;
    vars.grouping = id('grouping').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SanitizeAppGAPHeaders(vars), res.data);
        eListen(
          'cancelBtn',
          () => {
            SanitizeAppGAPHeaders.cancelReason();
          },
          'click'
        );
        eListen(
          'submitBtn',
          () => {
            SanitizeAppGAPHeaders.updateApplicationGAP();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  SAs_headersAA: (recipe, hdrs, vars) => {
    recipe.methods = ['getApplicationDetail', 'listAllocatedAssessmentOne', 'getReportsAllocatedToApplication', 'getAAFeedbackRequestor', 'getAAReportViewer', 'getLinkedReportViewers', 'getLinkedHRBP', 'getAAHRContact', 'getLocations', 'getBusinessEntities', 'GAPCostCenter', 'GAPJobTitle', 'GAPPositionLevel', 'GAPJobDivision', 'getAdditionalServicesByClient', 'getAARequestors', 'getAAHRContact', 'getAAHiringmanager', 'getAAReportViewer', 'getTimezones', 'getAAFeedbackRequestor'];
    vars.HclientID = 42;
    vars.HapplicationID = id('HapplicationID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SanitizeAppAAHeaders(vars), res.data);
        eListen(
          'reactivateBtn',
          () => {
            applications.reactivateApp();
          },
          'click'
        );
        eListen(
          'cancelBtn',
          () => {
            SanitizeAppAAHeaders.cancelReason();
          },
          'click'
        );
        eListen(
          'addRepViewer',
          () => {
            RecordAngloApp.addReportViewer();
          },
          'click'
        );
        eListen(
          'addHR',
          () => {
            RecordAngloApp.addHR();
          },
          'click'
        );
        eListen(
          'busEntity',
          () => {
            notEmptyVal('busEntity');
            RecordAngloApp.populatelegalEnt();
            RecordAngloApp.includeMPA();
          },
          'change'
        );
        eListen(
          'legalEntity',
          () => {
            notEmptyVal('legalEntity');
            RecordAngloApp.populateAssPackage();
            RecordAngloApp.changeRegLang();
          },
          'change'
        );
        eListen(
          'workLevel',
          () => {
            notEmptyVal('workLevel');
            RecordAngloApp.populateAssPackage();
            RecordAngloApp.includeMPA();
          },
          'change'
        );
        eListen(
          'assPurpose',
          () => {
            notEmptyVal('assPurpose');
            RecordAngloApp.assPurposeOpt();
            RecordAngloApp.populateAssPackage();
            RecordAngloApp.includeMPA();
          },
          'change'
        );
        eListen(
          'HRContact',
          () => {
            notEmptyVal('HRContact');
            RecordAngloApp.isNewHR();
          },
          'change'
        );
        eListen(
          'recordAngloApps',
          () => {
            SanitizeAppAAHeaders.updateApplicationAA();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  SAt_contactForm: (recipe, hdrs, vars) => {
    recipe.methods = ['getApplicationDetail', 'listAllocatedAssessmentOne', 'getOtherApplications'];
    vars.HapplicationID = id('HapplicationID').value;
    vars.HclientID = id('HclientID').value;
    vars.grouping = id('grouping').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SAt_contactForm(vars), res.data);
        eListen(
          'f_profile',
          () => {
            SAt_contactForm.recordApplicationComments();
          },
          'click'
        );
        eListen(
          'f_viewComments',
          () => {
            SAt_contactForm.viewApplicationComments();
          },
          'click'
        );
        eListen(
          'bookingDate',
          () => {
            notEmptyVal('bookingDate');
          },
          'change'
        );
        eListen(
          'CFs_bookingDate',
          () => {
            SAt_contactForm.checkExistsValid();
          },
          'click'
        );
        // SAt_contactForm.recordBookingDateNew();

        eListen(
          'CFs_email',
          () => {
            changeSubTab('CFs_email');
          },
          'click'
        );
        eListen(
          'CFs_SMS',
          () => {
            changeSubTab('CFs_SMS');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  CFs_email: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        initPageInstance(new CFs_email());
        eListen(
          'CFs_sendEmail',
          () => {
            CFs_email.sendEmail();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  CFs_SMS: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        initPageInstance(new CFs_SMS());
        eListen(
          'CFs_sendSMS',
          () => {
            CFs_SMS.sendSMS();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  SAt_addAssessments: (recipe, hdrs, vars) => {
    recipe.methods = ['getAllocatedExternalAssessmentsToUser', 'getAllocatedProjectsToUser', 'getExternalAssessmentsForAlloc', 'getJVROProjectsForAlloc', 'getExternalAssessmentsForAlloc'];
    vars.candidateID = id('editUserID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SAt_addAssessments(vars), res.data);
        eListen(
          'SAt_allocateProject',
          () => {
            allocateProject('project');
          },
          'click'
        );
        eListen(
          'SAt_allocateAssessment',
          () => {
            allocateProject('assessment');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  SAt_history: (recipe, hdrs, vars) => {
    recipe.methods = ['getApplicationHistory', 'getApplicationHistory2'];
    if (vars.HAPPLICATIONID) {
      delete vars.HAPPLICATIONID;
    }
    vars.HapplicationID = id('HapplicationID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SAt_history(vars), res.data);
      })
      .catch(console.error());
  },
  SAt_addServices: (recipe, hdrs, vars) => {
    recipe.methods = ['getAdditionalServices', 'getAdditionalServicesAllocated', 'getFeedbackRequestor'];
    vars.applicationID = id('HapplicationID').value || vars.happlicationID;
    vars.happlicationID = id('HapplicationID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SAt_addServices(vars), res.data);

        eListen(
          'expressService',
          () => {
            additionalService.checkRequest();
          },
          'click'
        );
        eListen(
          'requestorInput',
          () => {
            filterOptionsHandler('requestorInput', 'requester');
          },
          'keyup'
        );
        eListen(
          'requester',
          () => {
            additionalService.newRequester('requester');
          },
          'change'
        );
        eListen(
          'requester',
          () => {
            onblurVal('requester');
          },
          'blur'
        );
        eListen(
          'SAt_requestServies',
          () => {
            SAt_addServices.requestAddServices2();
          },
          'click'
        );
        eListen(
          'fbDuration',
          () => {
            onblurVal('fbDuration');
          },
          'blur'
        );
        eListen(
          'email',
          () => {
            eAddressVal('email');
          },
          'keyup'
        );
        eListen(
          'sName',
          () => {
            notEmptyVal('sName');
          },
          'keyup'
        );
        eListen(
          'fName',
          () => {
            notEmptyVal('fName');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  SAt_manStatus: (recipe, hdrs, vars) => {
    recipe.methods = ['getApplicatonStatusForAdminUpdate', 'getApplicationDetail'];
    vars.HapplicationID = id('HapplicationID').value;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new SAt_manStatus(vars), res.data);
        eListen(
          'SAt_recordAppResult',
          () => {
            SAt_manStatus.recordAppResult();
          },
          'click'
        );
        eListen(
          'f_changeStatus',
          () => {
            notEmptyVal('f_changeStatus');
          },
          'change'
        );
        eListen(
          'SAt_hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'changeBtnContainer',
          () => {
            SAt_manStatus.changeApplicationStatus();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  // Manage Applications
  manageApplications: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new ManageApplications());
        eListen(
          'searchFilters',
          () => {
            applications.searchFilters();
          },
          'click'
        );
        eListen(
          'feedbackBlock',
          () => {
            applications.changeAppState('feedbackBlock', 'FBT');
          },
          'click'
        );
        eListen(
          'cancelBlock',
          () => {
            applications.changeAppState('cancelBlock', 'Can');
          },
          'click'
        );
        eListen(
          'compBlock',
          () => {
            applications.changeAppState('compBlock', 'Com');
          },
          'click'
        );
        eListen(
          'currBlock',
          () => {
            applications.changeAppState('currBlock', 'o');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  viewAppDetails: (recipe, hdrs, vars) => {
    vars.applicationID = vars.HAPPLICATIONID || vars.happlicationID;
    recipe.methods = ['getApplicationDetail', 'listAllocatedAssessmentOne', 'getApplicationHistory2', 'getReportsAllocatedToApplication', 'getFeedbackRequestor', 'getAdditionalServices', 'getAdditionalServicesAllocated', 'getAdditionalAssOpts'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection([]);
        initPageInstance(new ViewAppDetails(vars), res.data);
        eListen(
          'recordAppResults',
          () => {
            ViewAppDetails.recordAppResult();
          },
          'click'
        );
        eListen(
          'requestAdditionalServices',
          () => {
            ViewAppDetails.additionalServicesWarningPopup();
          },
          'click'
        );
        eListen(
          'btn_addAdditionalAss',
          () => {
            ViewAppDetails.addAdditionalAss();
          },
          'click'
        );
        eListen(
          'requester',
          [
            () => {
              additionalService.newRequester('requester');
            },
            () => {
              onblurVal('requester');
            },
          ],
          ['change', 'blur']
        ); //checked
        eListen(
          'requestorInput',
          () => {
            filterOptionsHandler('requestorInput', 'requester');
          },
          'keyup'
        );
        eListen(
          'fName',
          () => {
            notEmptyVal('fName');
          },
          'keyup'
        );
        eListen(
          'sName',
          () => {
            notEmptyVal('sName');
          },
          'keyup'
        );
        eListen(
          'email',
          () => {
            eAddressVal('email');
          },
          'keyup'
        );
        eListen(
          'fbDuration',
          () => {
            onblurVal('fbDuration');
          },
          'blur'
        );
      })
      .catch(console.error());
  },
  // AA Aplications
  angloApplications: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        initPageInstance(new AngloApplications(), res.data);
        eListen(
          'searchFilters',
          () => {
            applications.searchFilters();
          },
          'click'
        );
        eListen(
          'archBlock',
          () => {
            applications.changeAppState('archBlock', 'Can');
          },
          'click'
        );
        eListen(
          'compBlock',
          () => {
            applications.changeAppState('compBlock', 'Com');
          },
          'click'
        );
        eListen(
          'currBlock',
          () => {
            applications.changeAppState('currBlock', 'o');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  recordAngloApp: (recipe, hdrs, vars) => {
    recipe.methods = ['getLocations', 'getBusinessEntities', 'GAPCostCenter', 'GAPJobTitle', 'GAPPositionLevel', 'GAPJobDivision', 'getAdditionalServicesByClient', 'getAARequestors', 'getAAHRContact', 'getAAHiringmanager', 'getAAReportViewer', 'getTimezones', 'getAAFeedbackRequestor']; //getassPackages getSuccessprofile getDDlegalEntity getLanguagesPerClient
    vars.HclientID = 42; //Anglo: 42
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        initPageInstance(new RecordAngloApp(vars), res.data);
        eListen(
          'busEntity',
          () => {
            notEmptyVal('busEntity');
            RecordAngloApp.populatelegalEnt();
            RecordAngloApp.includeMPA();
          },
          'change'
        );
        eListen(
          'newbusEntity',
          () => {
            notEmptyVal('newbusEntity');
          },
          'keyup'
        );
        eListen(
          'legalEntity',
          () => {
            notEmptyVal('legalEntity');
            RecordAngloApp.populateAssPackage();
            RecordAngloApp.changeRegLang();
          },
          'change'
        );
        eListen(
          'newlegalEntity',
          () => {
            notEmptyVal('newlegalEntity');
          },
          'keyup'
        );
        eListen(
          'workLevel',
          () => {
            notEmptyVal('workLevel');
            RecordAngloApp.populateAssPackage();
            RecordAngloApp.includeMPA();
          },
          'change'
        );
        eListen(
          'newworkLevel',
          () => {
            notEmptyVal('newworkLevel');
          },
          'keyup'
        );
        eListen(
          'assPurpose',
          () => {
            notEmptyVal('assPurpose');
            RecordAngloApp.assPurposeOpt();
            RecordAngloApp.populateAssPackage();
            RecordAngloApp.includeMPA();
            RecordAngloApp.changeExcel();
          },
          'change'
        );
        eListen(
          'assPackage',
          () => {
            notEmptyVal('assPackage');
          },
          'change'
        );
        eListen(
          'purchaseOrderNum',
          () => {
            notEmptyVal('purchaseOrderNum');
          },
          'keyup'
        );
        eListen(
          'costCentre',
          () => {
            notEmptyVal('costCentre');
          },
          'change'
        );
        eListen(
          'newcostCentre',
          () => {
            notEmptyVal('newcostCentre');
          },
          'keyup'
        );
        eListen(
          'roleTitle',
          () => {
            notEmptyVal('roleTitle');
          },
          'change'
        );
        eListen(
          'newroleTitle',
          () => {
            notEmptyVal('newroleTitle');
          },
          'keyup'
        );
        eListen(
          'busUnit',
          () => {
            notEmptyVal('busUnit');
          },
          'change'
        );
        eListen(
          'newbusUnit',
          () => {
            notEmptyVal('newbusUnit');
          },
          'keyup'
        );
        eListen(
          'leadershipScope',
          () => {
            notEmptyVal('leadershipScope');
          },
          'change'
        );
        eListen(
          'location',
          () => {
            notEmptyVal('location');
          },
          'change'
        );
        eListen(
          'newlocation',
          () => {
            notEmptyVal('newlocation');
          },
          'keyup'
        );
        eListen(
          'timeZone',
          () => {
            notEmptyVal('timeZone');
            RecordAngloApp.timeZoneDescroption();
          },
          'change'
        );
        eListen(
          'recruiter',
          () => {
            notEmptyVal('recruiter');
          },
          'change'
        );
        eListen(
          'fNameR',
          () => {
            notEmptyVal('fNameR');
          },
          'keyup'
        );
        eListen(
          'sNameR',
          () => {
            notEmptyVal('sNameR');
          },
          'keyup'
        );
        eListen(
          'emailR',
          () => {
            eAddressVal('emailR');
          },
          'keyup'
        );
        eListen(
          'cellR',
          () => {
            mobNumVal('cellR');
          },
          'keyup'
        );
        eListen(
          'hiringManager',
          () => {
            notEmptyVal('hiringManager');
          },
          'change'
        );
        eListen(
          'fNameHM',
          () => {
            notEmptyVal('fNameHM');
          },
          'keyup'
        );
        eListen(
          'sNameHM',
          () => {
            notEmptyVal('sNameHM');
          },
          'keyup'
        );
        eListen(
          'emailHM',
          () => {
            eAddressVal('emailHM');
          },
          'keyup'
        );
        eListen(
          'cellHM',
          () => {
            mobNumVal('cellHM');
          },
          'keyup'
        );
        eListen(
          'HRContact',
          () => {
            notEmptyVal('HRContact');
            RecordAngloApp.isNewHR();
          },
          'change'
        );
        eListen(
          'bulkUploadToggle',
          () => {
            RecordAngloApp.bulkToggle();
          },
          'change'
        );
        eListen(
          'canName',
          () => {
            notEmptyVal('canName');
          },
          'keyup'
        );
        eListen(
          'canSName',
          () => {
            notEmptyVal('canSName');
          },
          'keyup'
        );
        eListen(
          'appEmployeeID',
          () => {
            notEmptyVal('appEmployeeID');
          },
          'keyup'
        );
        eListen(
          'jobID',
          () => {
            notEmptyVal('jobID');
          },
          'keyup'
        );
        eListen(
          'canPhoneNum',
          () => {
            mobNumVal('canPhoneNum');
          },
          'keyup'
        );
        eListen(
          'canEmail',
          () => {
            eAddressVal('canEmail');
          },
          'keyup'
        );
        eListen(
          'upload',
          () => {
            RecordAngloApp.displayFile(id('upload').files[0]);
          },
          'change'
        );
        eListen(
          'importBtn',
          () => {
            RecordAngloApp.uploadApps();
          },
          'click'
        );
        eListen(
          'addReportViewer',
          () => {
            RecordAngloApp.addReportViewer();
          },
          'click'
        );
        eListen(
          'fNameRV',
          () => {
            notEmptyVal('fNameRV');
          },
          'keyup'
        );
        eListen(
          'sNameRV',
          () => {
            notEmptyVal('sNameRV');
          },
          'keyup'
        );
        eListen(
          'f_cellNr',
          () => {
            mobNumVal('f_cellNr');
          },
          'keyup'
        );
        eListen(
          'eAddressRV',
          () => {
            eAddressVal('eAddressRV');
          },
          'keyup'
        );
        eListen(
          'recordAngloAppBtn',
          () => {
            RecordAngloApp.recordApplication();
          },
          'click'
        );
        eListen(
          'addHR',
          () => {
            RecordAngloApp.addHR();
          },
          'click'
        );
        eListen(
          'hideValFail',
          () => {
            hide('valFail');
          },
          'click'
        );
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  viewAngloApp: (recipe, hdrs, vars) => {
    vars.HclientID = 42; //Anglo: 42
    vars.applicationID = vars.HAPPLICATIONID || vars.happlicationID;
    recipe.methods = ['getApplicationDetail', 'listAllocatedAssessmentOne', 'getApplicationHistory2', 'getAdditionalServices', 'getAdditionalServicesAllocated', 'getReportsAllocatedToApplication', 'getAAFeedbackRequestor', 'getAAReportViewer', 'getLinkedReportViewers', 'getLinkedHRBP', 'getAAHRContact']; //
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        initPageInstance(new ViewAngloApplication(vars), res.data);
        id('HapplicationID').value = vars.HAPPLICATIONID;
        eListen(
          'duplicateBtn',
          () => {
            ViewAngloApplication.duplicateApp();
          },
          'click'
        );
        eListen(
          'updateMPAbtn',
          () => {
            ViewAngloApplication.updateMPA();
          },
          'click'
        );
        eListen(
          'addServicesPopup',
          () => {
            ViewAngloApplication.additionalServicesWarningPopup();
          },
          'click'
        );
        eListen(
          'addReportView',
          () => {
            ViewAngloApplication.addReportViewerNew();
          },
          'click'
        );
        eListen(
          'addHRBP',
          () => {
            ViewAngloApplication.addHRBP();
          },
          'click'
        );
        eListen(
          'recordAppResult',
          () => {
            ViewAngloApplication.recordAppResult();
          },
          'click'
        );
        eListen(
          'Include',
          () => {
            ViewAngloApplication.disableBtnMPA();
          },
          'change'
        );
        eListen(
          'Exclude',
          () => {
            ViewAngloApplication.disableBtnMPA();
          },
          'change'
        );
        eListen(
          'requester',
          [
            () => {
              additionalService.newRequester('requester');
            },
            () => {
              onblurVal('requester');
            },
          ],
          ['change', 'blur']
        ); //checked
        eListen(
          'HRContact',
          () => {
            notEmptyVal('HRContact');
          },
          'change'
        );
        eListen(
          'requestorInput',
          () => {
            filterOptionsHandler('requestorInput', 'requester');
          },
          'keyup'
        );
        eListen(
          'fName',
          () => {
            notEmptyVal('fName');
          },
          'keyup'
        );
        eListen(
          'sName',
          () => {
            notEmptyVal('sName');
          },
          'keyup'
        );
        eListen(
          'email',
          () => {
            eAddressVal('email');
          },
          'keyup'
        );
        eListen(
          'fbDuration',
          () => {
            onblurVal('fbDuration');
          },
          'blur'
        );
        eListen(
          'fNameRV',
          () => {
            notEmptyVal('fNameRV');
          },
          'keyup'
        );
        eListen(
          'sNameRV',
          () => {
            notEmptyVal('sNameRV');
          },
          'keyup'
        );
        eListen(
          'f_cellNr',
          () => {
            mobNumVal('f_cellNr');
          },
          'keyup'
        );
        eListen(
          'eAddressRV',
          () => {
            eAddressVal('eAddressRV');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  // Discovery Applications
  applications: (recipe, hdrs, vars) => {
    recipe.methods = ['getDiscoveryApplications'];
    vars.ApplicationState = 'o';
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        initPageInstance(new DiscoveryApplications(vars), res.data);
        eListen(
          'currBlock',
          () => {
            applications.changeAppState('currBlock', 'o');
          },
          'click'
        );
        eListen(
          'compBlock',
          () => {
            applications.changeAppState('compBlock', 'Com');
          },
          'click'
        );
        eListen(
          'cancelBlock',
          () => {
            applications.changeAppState('cancelBlock', 'Can');
          },
          'click'
        );
        eListen(
          'currTeamBlock',
          () => {
            applications.changeAppState('currTeamBlock', 't');
          },
          'click'
        );
        eListen(
          'feedbackBlock',
          () => {
            applications.changeAppState('feedbackBlock', 'FBT');
          },
          'click'
        );
        eListen(
          'searchFilters',
          () => {
            applications.searchFilters();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  addApplications: (recipe, hdrs, vars) => {
    vars.HclientID = 4;
    vars.grouping = 'application';
    recipe.methods = ['getDDCountry', 'getDDPositionLevel', 'getDiscoveryLineManagers', 'getDDCostCenters', 'getDDdivisions', 'getDDjobfamily', 'getDiscoveryRecruiters'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection([]);
        initPageInstance(new RecordDiscoveryApplication(vars), res.data);
        eListen(
          'submitBtn',
          () => {
            RecordDiscoveryApplication.recordDiscApplication();
          },
          'click'
        );
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'expressService',
          () => {
            additionalService.checkRequest();
          },
          'click'
        );
        eListen(
          'c_f_recruiterBtn',
          () => {
            RecordDiscoveryApplication.addNew('recruiterID', 'c_f_recruiter', 'c_f_recruiterBtn', 'newhiringManagerDiv');
          },
          'click'
        );
        eListen(
          'c_f_hiringManagerBtn',
          () => {
            RecordDiscoveryApplication.addNew('hiringManagerID', 'c_f_hiringManager', 'c_f_hiringManagerBtn', 'newc_f_hiringManagerDiv');
          },
          'click'
        );
        eListen(
          'divisionBtn',
          () => {
            RecordDiscoveryApplication.addNew('DDdivisionID', 'newDivisionName', 'divisionBtn');
          },
          'click'
        );
        eListen(
          'costCentreBtn',
          () => {
            RecordDiscoveryApplication.addNew('DDcostCenterID', 'newCostCenterName', 'costCentreBtn');
          },
          'click'
        );
        eListen(
          'countrySelect',
          () => {
            RecordDiscoveryApplication.selectCountry();
          },
          'click'
        );
        eListen(
          'preferredDate',
          () => {
            notEmptyVal('preferredDate');
          },
          'change'
        );
        eListen(
          'preferredLocation',
          () => {
            notEmptyVal('preferredLocation');
          },
          'change'
        );
        eListen(
          'purpose',
          () => {
            notEmptyVal('purpose');
          },
          'change'
        );
        eListen(
          'DDpositionLevelID',
          () => {
            notEmptyVal('DDpositionLevelID');
          },
          'change'
        );
        eListen(
          'DDjobFamilyID',
          () => {
            notEmptyVal('DDjobFamilyID');
          },
          'change'
        );
        eListen(
          'DDcostCenterID',
          () => {
            notEmptyVal('DDcostCenterID');
          },
          'change'
        );
        eListen(
          'DDdivisionID',
          () => {
            notEmptyVal('DDdivisionID');
          },
          'change'
        );
        eListen(
          'hiringManagerID',
          () => {
            notEmptyVal('hiringManagerID');
          },
          'change'
        );
        eListen(
          'recruiterID',
          () => {
            notEmptyVal('recruiterID');
          },
          'change'
        );
        eListen(
          'candidatefname',
          () => {
            notEmptyVal('candidatefname');
          },
          'keyup'
        );
        eListen(
          'candidatesname',
          () => {
            notEmptyVal('candidatesname');
          },
          'keyup'
        );
        eListen(
          'candidateIDnr',
          () => {
            saIDVal('candidateIDnr');
          },
          'keyup'
        );
        eListen(
          'candidatePassportnr',
          () => {
            notEmptyVal('candidatePassportnr');
          },
          'keyup'
        );
        eListen(
          'candidateemailAddress',
          () => {
            eAddressVal('candidateemailAddress');
          },
          'keyup'
        );
        eListen(
          'candidatemobileNo',
          () => {
            mobNumVal('candidatemobileNo');
          },
          'keyup'
        );
        eListen(
          'positionNumber',
          () => {
            notEmptyVal('positionNumber');
          },
          'keyup'
        );
        eListen(
          'rfcNr',
          [
            () => {
              notEmptyVal('rfcNr');
            },
            () => {
              RecordDiscoveryApplication.searchRFC();
            },
          ],
          ['keyup', 'blur']
        );
        eListen(
          'clientcomments',
          () => {
            notEmptyVal('clientcomments');
          },
          'keyup'
        );
        eListen(
          'jobTitle',
          () => {
            notEmptyVal('jobTitle');
          },
          'keyup'
        );
        eListen(
          'c_f_positionlevel',
          () => {
            filterOptionsHandler('c_f_positionlevel', 'DDpositionLevelID');
          },
          'keyup'
        );
        eListen(
          'newjobFamilyName',
          () => {
            filterOptionsHandler('newjobFamilyName', 'DDjobFamilyID');
          },
          'keyup'
        );
        eListen(
          'newCostCenterName',
          () => {
            filterOptionsHandler('newCostCenterName', 'DDcostCenterID');
          },
          'keyup'
        );
        eListen(
          'newDivisionName',
          () => {
            filterOptionsHandler('newDivisionName', 'DDdivisionID');
          },
          'keyup'
        );
        eListen(
          'c_f_hiringManager',
          () => {
            filterOptionsHandler('c_f_hiringManager', 'hiringManagerID');
          },
          'keyup'
        );
        eListen(
          'hiringManagerIDfname',
          () => {
            notEmptyVal('hiringManagerIDfname');
          },
          'keyup'
        );
        eListen(
          'hiringManagerIDsname',
          () => {
            notEmptyVal('hiringManagerIDsname');
          },
          'keyup'
        );
        eListen(
          'hiringManagerIDemailaddress',
          () => {
            eAddressVal('hiringManagerIDemailaddress');
          },
          'keyup'
        );
        eListen(
          'hiringManagerIDmobileNo',
          () => {
            mobNumVal('hiringManagerIDmobileNo');
          },
          'keyup'
        );
        eListen(
          'c_f_recruiter',
          () => {
            filterOptionsHandler('c_f_recruiter', 'recruiterID');
          },
          'keyup'
        );
        eListen(
          'recruiterIDfname',
          () => {
            notEmptyVal('recruiterIDfname');
          },
          'keyup'
        );
        eListen(
          'recruiterIDsname',
          () => {
            notEmptyVal('recruiterIDsname');
          },
          'keyup'
        );
        eListen(
          'recruiterIDemailaddress',
          () => {
            eAddressVal('recruiterIDemailaddress');
          },
          'keyup'
        );
        eListen(
          'recruiterIDmobileNo',
          () => {
            mobNumVal('recruiterIDmobileNo');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  viewApplication: (recipe, hdrs, vars) => {
    vars.applicationID = vars.HAPPLICATIONID || vars.happlicationID;
    recipe.methods = ['getApplicationDetail', 'listAllocatedAssessmentOne', 'getApplicationHistory2', 'getAdditionalServices', 'getAdditionalServicesAllocated', 'getReportsAllocatedToApplication', 'getFeedbackRequestor'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        initPageInstance(new ViewDiscoveryApplication(vars), res.data);
        id('HapplicationID').value = vars.HAPPLICATIONID;
        eListen(
          'expressService',
          () => {
            additionalService.checkRequest();
          },
          'click'
        );
        eListen(
          'reqService',
          () => {
            ViewDiscoveryApplication.requestAddServicesDisc();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  // Reports
  manageReports: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new ManageReports());
      })
      .catch(console.error());
  },
  addReport: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new AddReport());
        eListen(
          'btn_editReports',
          () => {
            AddReport.addReport();
          },
          'click'
        );
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'messageEvent_select',
          () => {
            notEmptyVal('messageEvent_select');
          },
          'change'
        );
        eListen(
          'completeAss_select',
          () => {
            notEmptyVal('completeAss_select');
          },
          'change'
        );
        eListen(
          'reportShortname',
          () => {
            notEmptyVal('reportShortname');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  editReports: (recipe, hdrs, vars) => {
    recipe.tabs = 1;
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        id('hreportTypeID').value = vars.HreportTypeID;
        id('HclientID').value = vars.HclientID;
        initPageInstance(new EditReport(vars), res.data);
        eListen(
          'MRt_msgEvents',
          () => {
            changeTabN('MRt_msgEvents');
          },
          'click'
        );
        eListen(
          'MRt_headers',
          () => {
            changeTabN('MRt_headers');
          },
          'click'
        );
        eListen(
          'MRt_userRoles',
          () => {
            changeTabN('MRt_userRoles');
          },
          'click'
        );
      })
      .catch(console.error());
  },
  MRt_headers: (recipe, hdrs, vars) => {
    vars.messageEventType = 'Upload report';
    vars.HreportTypeID = id('hreportTypeID').value;
    vars.HclientID = id('HclientID').value;

    recipe.methods = ['getMessageEvents', 'getReportDetails', 'getAssessmentsPerClient'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MRtReportHeaders(vars), res.data);
        eListen(
          'hideFeedbackBanner',
          () => {
            hide('feedbackBanner');
          },
          'click'
        );
        eListen(
          'btn_editReports',
          () => {
            MRtReportHeaders.updateReport();
          },
          'click'
        );
        eListen(
          'messageEvent_select',
          () => {
            notEmptyVal('messageEvent_select');
          },
          'change'
        );
        eListen(
          'completeAss_select',
          () => {
            notEmptyVal('completeAss_select');
          },
          'change'
        );
        eListen(
          'reportShortname',
          () => {
            notEmptyVal('reportShortname');
          },
          'keyup'
        );
      })
      .catch(console.error());
  },
  MRt_msgEvents: (recipe, hdrs, vars) => {
    vars.messageEventType = 'Upload report';
    recipe.methods = ['getReportDetails', 'getMessageEvents', 'getMessageEventsByReportType'];
    vars.HclientID = id('HclientID').value;
    vars.HreportTypeID = parseInt(id('hreportTypeID').value);

    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MRtMsgEvents(vars), res.data);
        eListen(
          'btn_addMsgEvent',
          () => {
            MRtMsgEvents.addMsgEventToReportTye();
          },
          'click'
        );
        eListen(
          'messageEvent_select',
          () => {
            notEmptyVal('messageEvent_select');
          },
          'change'
        );
        eListen(
          'dynamicRecipient',
          () => {
            notEmptyVal('dynamicRecipient');
          },
          'change'
        );
      })
      .catch(console.error());
  },
  MRt_userRoles: (recipe, hdrs, vars) => {
    recipe.methods = ['getDDroles', 'getRoleAllocReport'];
    vars.HclientID = id('HclientID').value;
    vars.HreporttypeID = parseInt(id('hreportTypeID').value);

    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        initPageInstance(new MRtUserRoles(vars), res.data);
        eListen(
          'btn_userRole',
          () => {
            MRtUserRoles.addUserRoleToReport();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_appstatus: (recipe, hdrs, vars) => {
    recipe.methods = ['getHTMLLexicon'];
    vars.HTMLPageName = 'arep_appstatus';
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['clientSelect']);
        adminReporting.buildAppStatus(res.data.getHTMLLexicon);
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_assstatus: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        assStatusReport.page();
        eListen(
          'expData',
          () => {
            assStatusReport.exportData();
          },
          'click'
        );

        eListen(
          'emailData',
          () => {
            assStatusReport.emailData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_asssla: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        bookSLA.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_repdel: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        reportSLA.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_jobs: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        changeTabN('JFt_jobLevels');
      })
      .catch(console.error());
  },
  arep_asspurpose: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        assPurpose.buildAppStatus();
        eListen(
          'expData',
          () => {
            assPurpose.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_reportRegister: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        reportRegister.reportRegPage();
        eListen(
          'reportTypeDD',
          () => {
            reportRegister.DDchange();
          },
          'change'
        );
        eListen(
          'reportStatusDD',
          () => {
            reportRegister.DDchange();
          },
          'change'
        );
        eListen(
          'projectNameDD',
          () => {
            reportRegister.DDchange();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  arep_reportReview: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        reportReview.reportReviewPage();
        eListen(
          'reportTypeDD',
          () => {
            reportReview.DDchange();
          },
          'change'
        );
        eListen(
          'projectNameDD',
          () => {
            reportReview.DDchange();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  arep_reportFailures: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        reportFailure.reportFailPage();
        eListen(
          'reportTypeDD',
          () => {
            reportFailure.DDchange();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  arep_AADBBilling: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['']);
        eListen(
          'f_profile',
          () => {
            AADBBilling.getBillingDownload();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_adminReports: (recipe, hdrs, vars) => {
    recipe.methods = ['getHTMLLexicon'];
    vars.HTMLPageName = 'arep_adminReports';
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['']);
        adminReports.langObj = {};
        for (let row of res.data.getHTMLLexicon) {
          adminReports.langObj[row.marker] = row.display;
        }
        eListen(
          'f_profile',
          () => {
            adminReports.getReportsDownload();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  JFt_jobLevels: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        jobLevels.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  JFt_servicePerBisinessUnit: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        servicePBUnit.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  JFt_publication: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        jobPublication.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  JFt_family: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        jobFamily.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  JFt_costCenter: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        costCenter.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },
  arep_assbooksla: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        assBookSLA.page();
        eListen(
          'expData',
          () => {
            adminReporting.exportData();
          },
          'click'
        );
      })
      .catch(console.error());
  },

  // Report Automation Rules
  reportAutoRules: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        viewReportRules.viewReportRulesPage();
        eListen(
          'globalBandDD',
          () => {
            viewReportRules.DDchange();
          },
          'change'
        );
        eListen(
          'uploadMethodDD',
          () => {
            viewReportRules.DDchange();
          },
          'change'
        );
        eListen(
          'projectNameDD',
          () => {
            viewReportRules.DDchange();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  rar_edit: (recipe, hdrs, vars) => {
    recipe.methods = ['GAPPositionLevel', 'getDDpurposeByClient', 'getDDReportUploadMethod'];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        // toggleSelectsection(['clientSelect']);
        editReportRules.editReportRulesPage(res.data, vars);
        eListen(
          'updRules',
          () => {
            editReportRules.updateRules();
          },
          'click'
        );
        eListen(
          'purposeDD',
          () => {
            editReportRules.DDchange();
          },
          'change'
        );
        eListen(
          'globalBandDD',
          () => {
            editReportRules.DDchange();
          },
          'change'
        );
        eListen(
          'batteryNameDD',
          () => {
            editReportRules.DDchange2();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  //Resource Register
  resourceRegister: (recipe, hdrs, vars) => {
    //  recipe.methods = [
    //    "getMessageEvents",
    //    "getReportDetails",
    //    "getAssessmentsPerClient",
    //  ];
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        toggleSelectsection(['clientSelect']);
        initPageInstance(new ResourceRegister(vars));
        eListen(
          'serviceRDD',
          () => {
            ResourceRegister.DDchange();
          },
          'change'
        );
        eListen(
          'serviceStatusDD',
          () => {
            ResourceRegister.DDchange();
          },
          'change'
        );
      })
      .catch(console.error());
  },
  //monitoring
  monitoring: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then((res) => {
        // toggleSelectsection(["clientSelect"]);
      })
      .catch(console.error());
  },
  MOt_reports: (recipe, hdrs, vars) => {
    SWITCHBOX(recipe, hdrs, vars)
      .then(() => {
        toggleSelectsection(['clientSelect']);
        MOt_Reports.page();
        eListen(
          'MOt_datebtn',
          () => {
            MOt_Reports.page();
          },
          'click'
        );
      })
      .catch(console.error());
  },
};
